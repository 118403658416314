<section class="bp-num-cl-0 book-page"
         v-if="sessionResolved && allFlightSearchImagesLoaded">
  <!-- <button class="z-5 absolute" @click="autofillPassengerDataOnePassenger"> Autofill Passenger Data   </button> -->
  <Header />
  <div class="bp-num-cl-1 flex justify-content-center mt-3 xl:mt-5">
    <div class="bp-num-cl-2 dynwid px-2">
      <div class="bp-num-cl-3 breadcrumb">
        <Breadcrumb :home="breadcrumbs.home"
                    :model="breadcrumbs.items">
          <template #item="{ item }">
            <a style="height: 30px; padding-left: 6px; padding-right: 6px;"
               :href="item.url"
               :class="{ active: item.state == 'current', 'disabled': item.state == 'next' }"
               class="bp-num-cl-4 breadcrumb-item flex justify-content-center align-items-center">
              <div class="bp-num-cl-5 flex">
                <img v-if="item.icon"
                     :src="item.icon"
                     class="bp-num-cl-6 home-icon">
                <div class="bp-num-cl-7 flex"
                     v-if="!item.icon">
                  <div>
                    {{ item.number }}{{'.'}}
                  </div>
                  <div class="bp-num-cl-8 hidden xl:block">
                    {{' '}}{{ item.label }}
                  </div>
                  <div class="bp-num-cl-9 block xl:hidden"
                       v-if="item.state === 'current'">
                    {{' '}}{{ item.label }}
                  </div>
                </div>
              </div>
            </a>
          </template>
        </Breadcrumb>
      </div>
      <h2 class="bp-num-cl-10 font-semibold mt-5 mb-2">
        {{ flightBookData.content.pageLabels.headline }}
      </h2>
    </div>
  </div>
  <div class="bp-num-cl-11 flex gray justify-content-center mt-1">
    <div class="bp-num-cl-12 dynwid">
      <div class="bp-num-cl-13 grid pt-6 pb-6"
           id="main-container">
        <div class="bp-num-cl-14 col-12 xl:col-9">
          <div class="bp-num-cl-15 grid grid-nogutter">
            <Accordion class="bp-num-cl-16 col-12"
                       v-model:activeIndex="detailsAccordionActiveIndex">
              <AccordionTab :header="flightBookData.content.pageLabels.flightDetails">

                <div class="bp-num-cl-17 hidden xl:block">
                  <!-- DEPARTURE -->
                  <div class="bp-num-cl-18 departure-legs">
                    <div class="bp-num-cl-19 departure-header flex flex-wrap">
                      <div class="bp-num-cl-20 px-6 col-12 flex">
                        <div class="bp-num-cl-21 col-6 xl:col-7 px-0 text-lg font-medium">
                          <span>
                            {{ flightBookData.content.pageLabels.outbound }}
                          </span>
                        </div>
                        <div class="bp-num-cl-22 col-6 xl:col-3 text-xs text-center">
                          <div class="bp-num-cl-23 text-gray-650">
                            {{ flightBookData.content.pageLabels.flightDurationTotal }}:
                          </div>
                          <div class="bp-num-cl-24 font-medium">
                            {{ selectedOfferData.flightDetails.flight.outbound.duration }}
                          </div>
                        </div>
                        <div class="bp-num-cl-25 hidden xl:block col-2">
                          <!-- empty col just in case -->
                        </div>
                      </div>
                      <div class="bp-num-cl-26 col-12 px-6 pt-0">
                        <div v-for="(leg, index) in selectedOfferData.flightDetails.flight.outbound.segments">
                          <div v-if="index > 0"
                               class="bp-num-cl-27 py-2">
                            <div>
                              <div class="bp-num-cl-28 flex bg-gray-200 border-round">
                                <div class="bp-num-cl-29 col-7 text-xs">
                                  {{ flightBookData.content.pageLabels.stopIn }} {{ getAirport(leg.departure.code).city
                                  }}
                                  ({{ leg.departure.code }})
                                </div>
                                <div class="bp-num-cl-30 col-5 xl:col-3 text-xs text-center">
                                  {{ leg.stopDuration }}
                                </div>
                                <div class="bp-num-cl-31 col-0 xl:col-2">
                                  <!-- empty col just in case -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="bp-num-cl-32 flex flex-wrap justify-content-between">
                            <div class="bp-num-cl-33 col-12 xl:col-7 px-0 flex justify-content-between">
                              <FlightTime class="bp-num-cl-34 start-time"
                                          :description="true"
                                          :airport="getAirport(leg.departure.code)"
                                          :date="leg.departure.date"
                                          :time="leg.departure.time">
                              </FlightTime>
                              <div class="bp-num-cl-35 flex flex-auto align-items-center">
                                <div class="bp-num-cl-36 col-12 px-4">
                                  <MobileStopDrawing>
                                  </MobileStopDrawing>
                                </div>
                              </div>
                              <FlightTime class="bp-num-cl-37 end-time"
                                          :description="true"
                                          :airport="getAirport(leg.arrival.code)"
                                          :date="leg.arrival.date"
                                          :time="leg.arrival.time">
                              </FlightTime>
                            </div>
                            <div
                                 class="bp-num-cl-38 col-3 flex align-items-center align-content-center text-xs text-gray-650 justify-content-start xl:justify-content-center">
                              {{ leg.duration }}
                            </div>
                            <div
                                 class="bp-num-cl-39 col-5 xl:col-2 px-2 xl:p-0 flex flex-wrap align-content-center justify-content-end flight-number">
                              <div class="bp-num-cl-40 w-12 flex align-items-center justify-content-end">
                                <div class="bp-num-cl-41 flex align-items-center">
                                  <img :src="getAirlineLogo(leg.marketingAirline)"
                                       class="bp-num-cl-42 dialog-details-logo"
                                       alt="Airline logo">
                                </div>
                                <div
                                     class="bp-num-cl-43 flight-code text-gray-650 text-xs pt-0 text-right pr-0 white-space-nowrap">
                                  {{ leg.marketingAirline }}-{{ leg.flightNumber }}
                                </div>
                              </div>
                              <div class="bp-num-cl-44 w-12 line-height-1 text-xs text-right">
                                {{flightBookData.content.airlines[leg.marketingAirline].name.slice(0,18)}}{{(flightBookData.content.airlines[leg.marketingAirline].name.length
                                > 18 && '.') || ''}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bp-num-cl-45 bg-gray-400 h1p">
                     
                  </div>
                  <!-- RETURN -->
                  <div class="bp-num-cl-46 return-legs">
                    <div v-if="selectedOfferData.flightDetails.flight.inbound"
                         class="bp-num-cl-47 return-header flex flex-wrap justify-content-between">
                      <div class="bp-num-cl-48 col-12 px-6 flex">
                        <div class="bp-num-cl-49 col-6 xl:col-7 px-0 text-lg font-medium">
                          <span>
                            {{flightBookData.content.pageLabels.inbound}}
                          </span>
                        </div>
                        <div class="bp-num-cl-50 col-6 xl:col-3 text-xs text-center">
                          <div class="bp-num-cl-51 text-gray-650">
                            {{ flightBookData.content.pageLabels.flightDurationTotal }}:
                          </div>
                          <div class="bp-num-cl-52 font-medium">
                            {{ selectedOfferData.flightDetails.flight.inbound.duration }}
                          </div>
                        </div>
                        <div class="bp-num-cl-53 col-0 xl:col-2">
                          <!-- empty col just in case -->
                        </div>
                      </div>
                      <div class="bp-num-cl-54 col-12 px-6 pt-0">
                        <div v-for="(leg, index) in selectedOfferData.flightDetails.flight.inbound.segments">
                          <div v-if="index > 0"
                               class="bp-num-cl-55 py-2">
                            <div>
                              <div class="bp-num-cl-56 flex bg-gray-200 border-round">
                                <div class="bp-num-cl-57 col-7 text-xs">
                                  {{ flightBookData.content.pageLabels.stopIn }} {{ getAirport(leg.departure.code).city
                                  }}
                                  ({{ leg.departure.code }})
                                </div>
                                <div class="bp-num-cl-58 col-5 xl:col-3 text-xs text-center">
                                  {{ leg.stopDuration }}
                                </div>
                                <div class="bp-num-cl-59 col-0 xl:col-2">
                                  <!-- empty col just in case -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="bp-num-cl-60 flex flex-wrap justify-content-between">
                            <div class="bp-num-cl-61 col-12 xl:col-7 px-0 flex justify-content-between">
                              <FlightTime class="bp-num-cl-62 start-time"
                                          :description="true"
                                          :airport="getAirport(leg.departure.code)"
                                          :date="leg.departure.date"
                                          :time="leg.departure.time">
                              </FlightTime>
                              <div class="bp-num-cl-63 flex flex-auto align-items-center">
                                <div class="bp-num-cl-64 col-12 px-4">
                                  <MobileStopDrawing>
                                  </MobileStopDrawing>
                                </div>
                              </div>
                              <FlightTime class="bp-num-cl-65 end-time"
                                          :description="true"
                                          :airport="getAirport(leg.arrival.code)"
                                          :date="leg.arrival.date"
                                          :time="leg.arrival.time">
                              </FlightTime>
                            </div>
                            <div
                                 class="bp-num-cl-66 col-3 flex align-items-center align-content-center text-xs text-gray-650 justify-content-start xl:justify-content-center">
                              {{ leg.duration }}
                            </div>
                            <div
                                 class="bp-num-cl-67 col-5 xl:col-2 px-2 xl:p-0 flex flex-wrap align-content-center justify-content-end flight-number">
                              <div class="bp-num-cl-68 w-12 flex align-items-center justify-content-end">
                                <div class="bp-num-cl-69 flex align-items-center">
                                  <img :src="getAirlineLogo(leg.marketingAirline)"
                                       class="bp-num-cl-70 dialog-details-logo"
                                       alt="Airline logo">
                                </div>
                                <div
                                     class="bp-num-cl-71 flight-code text-gray-650 text-xs pt-0 text-right pr-0 white-space-nowrap">
                                  {{ leg.marketingAirline }}-{{ leg.flightNumber }}
                                </div>
                              </div>
                              <div class="bp-num-cl-72 w-12 line-height-1 text-xs text-right">
                                {{flightBookData.content.airlines[leg.marketingAirline].name.slice(0,18)}}{{(flightBookData.content.airlines[leg.marketingAirline].name.length
                                > 18 && '.') || ''}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bp-num-cl-73 block xl:hidden">

                  <!-- HEADER -->
                  <div class="bp-num-cl-74 flex justify-content-between align-items-center p-3">
                    <div>{{ flightBookData.content.pageLabels.flightDetails }}</div>
                  </div>

                  <!-- CITIES -->
                  <div class="bp-num-cl-75 flex justify-content-center align-items-center">
                    <div class="bp-num-cl-76 text-xl">{{
                      selectedOfferData.flightDetails.flight.outbound.segments[0].departure.code
                      }}</div>
                    <div class="bp-num-cl-77 px-2"><i class="bp-num-cl-78 pi pi-sort-alt rotate-90"></i></div>
                    <div class="bp-num-cl-79 text-xl">{{
                      selectedOfferData.flightDetails.flight.outbound.segments[selectedOfferData.flightDetails.flight.outbound.segments.length
                      -1].arrival.code }}</div>
                  </div>

                  <!-- PRICE PER PERSON -->
                  <div class="bp-num-cl-80 flex justify-content-center pb-2">
                    <div>
                      <div class="bp-num-cl-81 price-per-person-label text-xs text-center mt-1">
                        {{ flightBookData.content.pageLabels.pricePerPerson }}
                      </div>
                      <div class="bp-num-cl-82 price-per-person-amount text-center text-xl font-bold">
                        <FormattedCurrency :amount="selectedOfferData.sidebar.priceInfo.totalPrice.amount"
                                           :currency="selectedOfferData.sidebar.priceInfo.totalPrice.currency"
                                           :currencyPosition="selectedOfferData.sidebar.priceInfo.totalPrice.currencyPosition" />
                      </div>
                    </div>
                  </div>
                  <div class="bp-num-cl-83 bg-gray-400 h1p"> </div>

                  <!-- LEGS -->
                  <!-- DEPARTURE -->
                  <div class="bp-num-cl-84 departure-legs">
                    <div class="bp-num-cl-85 departure-header flex flex-wrap justify-content-between">
                      <div class="bp-num-cl-86 col-5 text-lg font-medium pl-3"><span>{{
                          flightBookData.content.pageLabels.outbound
                          }}</span></div>
                      <div class="bp-num-cl-87 col-5 text-xs font-medium pb-0">
                        <div class="bp-num-cl-88 text-right pr-2">{{ flightBookData.content.pageLabels.flightDuration }}
                        </div>
                        <div class="bp-num-cl-89 text-right pr-2">{{
                          selectedOfferData.flightDetails.flight.outbound.duration }}
                        </div>
                      </div>
                      <div class="bp-num-cl-90 col-12 pt-0">
                        <div v-for="(leg, index) in selectedOfferData.flightDetails.flight.outbound.segments">
                          <div v-if="index > 0"
                               class="bp-num-cl-91 py-2">
                            <div class="bp-num-cl-92 grid bg-gray-200 border-round">
                              <div class="bp-num-cl-93 col-8 text-xs">
                                <span>{{ flightBookData.content.pageLabels.stopIn }} {{
                                  getAirport(leg.departure.code).city
                                  }}</span> <span class="bp-num-cl-94 text-gray-600">{{ leg.departure.code }}</span>
                              </div>
                              <div class="bp-num-cl-95 col-4 text-xs text-right">
                                {{ leg.stopDuration }}
                              </div>
                            </div>
                          </div>
                          <div class="bp-num-cl-96 flex flex-wrap justify-content-between align-items-center">
                            <div class="bp-num-cl-97 col-12 flex justify-content-between flight-times-and-wrapper">
                              <div>
                                <FlightTime class="bp-num-cl-98 start-time"
                                            :description="true"
                                            :airport="getAirport(leg.departure.code)"
                                            :date="leg.departure.date"
                                            :time="leg.departure.time"></FlightTime>
                              </div>
                              <div class="bp-num-cl-99 pt-3">
                                <MobileStopDrawing></MobileStopDrawing>
                              </div>
                              <div class="bp-num-cl-100 landing-airport">
                                <FlightTime class="bp-num-cl-101 end-time"
                                            :description="true"
                                            :airport="getAirport(leg.arrival.code)"
                                            :date="leg.arrival.date"
                                            :time="leg.arrival.time">
                                </FlightTime>
                              </div>
                            </div>
                            <div
                                 class="bp-num-cl-102 col-5 pb-0 flex flex-wrap justify-content-start align-items-center">
                              <div class="bp-num-cl-103 w-12 flex align-items-center">
                                <img :src="getAirlineLogo(leg.marketingAirline)"
                                     class="bp-num-cl-104 mobile-details-logo"
                                     alt="Airline logo">
                                <div class="bp-num-cl-105 text-gray-600 text-xs">
                                  {{ leg.marketingAirline }}-{{ leg.flightNumber }}
                                </div>
                              </div>
                            </div>
                            <div class="bp-num-cl-106 col-5 flex justify-content-end align-items-center text-xs">{{
                              leg.duration }}
                            </div>
                            <div class="bp-num-cl-107 w-12 line-height-1 text-xs pl-2 pt-1">
                              {{flightBookData.content.airlines[leg.marketingAirline].name}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bp-num-cl-108 bg-gray-400 h1p"> </div>

                  <!-- Return -->
                  <div class="bp-num-cl-109 return-legs">
                    <div v-if="selectedOfferData.flightDetails.flight.inbound"
                         class="bp-num-cl-110 return-header flex flex-wrap justify-content-between">
                      <div class="bp-num-cl-111 col-5 text-lg font-medium pl-3"><span>{{
                          flightBookData.content.pageLabels.inbound
                          }}</span></div>
                      <div class="bp-num-cl-112 col-5 text-xs font-medium pb-0">
                        <div class="bp-num-cl-113 text-right pr-2">{{ flightBookData.content.pageLabels.flightDuration
                          }}</div>
                        <div class="bp-num-cl-114 text-right pr-2">{{
                          selectedOfferData.flightDetails.flight.inbound.duration }}</div>
                      </div>
                      <div class="bp-num-cl-115 col-12 pt-0">
                        <div v-for="(leg, index) in selectedOfferData.flightDetails.flight.inbound.segments">
                          <div v-if="index > 0"
                               class="bp-num-cl-116 py-2">
                            <div class="bp-num-cl-117 grid bg-gray-200 border-round">
                              <div class="bp-num-cl-118 col-8 text-xs">
                                <span>{{ flightBookData.content.pageLabels.stopIn }} {{
                                  getAirport(leg.departure.code).city
                                  }}</span> <span class="bp-num-cl-119 text-gray-600">{{ leg.departure.code }}</span>
                              </div>
                              <div class="bp-num-cl-120 col-4 text-xs text-right">
                                {{ leg.stopDuration }}
                              </div>
                            </div>
                          </div>
                          <div class="bp-num-cl-121 flex flex-wrap justify-content-between">
                            <div class="bp-num-cl-122 col-12 flex justify-content-between flight-times-and-wrapper">
                              <div>
                                <FlightTime class="bp-num-cl-123 start-time"
                                            :description="true"
                                            :airport="getAirport(leg.departure.code)"
                                            :date="leg.departure.date"
                                            :time="leg.departure.time"></FlightTime>
                              </div>
                              <div class="bp-num-cl-124 pt-3">
                                <MobileStopDrawing></MobileStopDrawing>
                              </div>
                              <div class="bp-num-cl-125 landing-airport">
                                <FlightTime class="bp-num-cl-126 end-time"
                                            :description="true"
                                            :airport="getAirport(leg.arrival.code)"
                                            :date="leg.arrival.date"
                                            :time="leg.arrival.time">
                                </FlightTime>
                              </div>
                            </div>
                            <div
                                 class="bp-num-cl-127 col-5 pb-0 flex flex-wrap justify-content-start align-items-center">
                              <div class="bp-num-cl-128 w-12 flex align-items-center">
                                <img :src="getAirlineLogo(leg.marketingAirline)"
                                     class="bp-num-cl-129 mobile-details-logo"
                                     alt="Airline logo">
                                <div class="bp-num-cl-130 text-gray-600 text-xs">
                                  {{ leg.marketingAirline }}-{{ leg.flightNumber }}
                                </div>
                              </div>
                            </div>
                            <div
                                 class="bp-num-cl-131 col-5 flex justify-content-end align-items-center text-xs text-gray-600">
                              {{
                              leg.duration }}
                            </div>
                            <div class="bp-num-cl-132 w-12 line-height-1 text-xs pl-2 pt-1">
                              {{flightBookData.content.airlines[leg.marketingAirline].name}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bp-num-cl-133 bg-gray-400 h1p"> </div>
                  <!-- CLASS PERKS -->
                  <div class="bp-num-cl-134 p-3"
                       v-if="'outbound' in flightBookData.content.priceClasses">
                    <div class="bp-num-cl-135 font-bold pb-2">
                      {{ getPriceClass(flightBookData.content.priceClasses.outbound.priceCode).name }}
                    </div>
                    <div class="bp-num-cl-136 w-12 p-3 pl-0 grid grid-nogutter class-perks">
                      <div v-if="selectedOfferData.flightDetails.baggageAllowance.display.includes('outbound')"
                           class="bp-num-cl-137 w-12">
                        <div class="bp-num-cl-138 w-12">
                          <div>{{ flightBookData.content.pageLabels.departure }}
                            {{ (selectedOfferData.flightDetails.baggageAllowance.inbound &&
                            !selectedOfferData.flightDetails.baggageAllowance.display.includes('inbound') && '+') || ''
                            }}
                            {{ (selectedOfferData.flightDetails.baggageAllowance.inbound &&
                            !selectedOfferData.flightDetails.baggageAllowance.display.includes('inbound') &&
                            flightBookData.content.pageLabels.arrival) || '' }}
                          </div>


                          <div class="bp-num-cl-139 col-12 grid flex p-0">
                            <div class="bp-num-cl-140 col-12 p-0">
                              <div class="bp-num-cl-141 flex align-items-end h-2rem">
                                <div class="bp-num-cl-142 flex align-items-center justify-content-center pr-2">
                                  <img :src="selectedOfferData.baggageAllowance.checked[selectedOfferData.flightDetails.baggageAllowance.outbound.checked].image"
                                       class="bp-num-cl-143 h-2rem" />
                                </div>
                                <div class="bp-num-cl-144 font-medium text-xs line-height-1">
                                  <span>{{
                                    selectedOfferData.baggageAllowance.checked[selectedOfferData.flightDetails.baggageAllowance.outbound.checked].label
                                    }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="bp-num-cl-145 col-12 p-0">
                              <div class="bp-num-cl-146 flex align-items-end h-2rem">
                                <div class="bp-num-cl-147 flex align-items-center justify-content-center pr-2">
                                  <img :src="selectedOfferData.baggageAllowance.carryOn[selectedOfferData.flightDetails.baggageAllowance.outbound.carryOn].image"
                                       class="bp-num-cl-148 h-1rem" />
                                </div>
                                <div class="bp-num-cl-149 font-medium text-xs line-height-1">
                                  <span>{{
                                    selectedOfferData.baggageAllowance.carryOn[selectedOfferData.flightDetails.baggageAllowance.outbound.carryOn].label
                                    }}</span>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div v-if="selectedOfferData.flightDetails.baggageAllowance.display.includes('inbound')"
                           class="bp-num-cl-150 w-12">
                        <div class="bp-num-cl-151 w-12">
                          <div>{{ flightBookData.content.pageLabels.arrival }}</div>
                          <div class="bp-num-cl-152 col-12 grid flex p-0">
                            <div class="bp-num-cl-153 col-12 p-0">
                              <div class="bp-num-cl-154 flex align-items-end h-2rem">
                                <div class="bp-num-cl-155 flex align-items-center justify-content-center pr-2">
                                  <img :src="selectedOfferData.baggageAllowance.checked[selectedOfferData.flightDetails.baggageAllowance.inbound.checked].image"
                                       class="bp-num-cl-156 h-2rem" />
                                </div>
                                <div class="bp-num-cl-157 font-medium text-xs line-height-1">
                                  <span>{{
                                    selectedOfferData.baggageAllowance.checked[selectedOfferData.flightDetails.baggageAllowance.inbound.checked].label
                                    }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="bp-num-cl-158 col-12 p-0">
                              <div class="bp-num-cl-159 flex align-items-end h-2rem">
                                <div class="bp-num-cl-160 flex align-items-center justify-content-center pr-2">
                                  <img :src="selectedOfferData.baggageAllowance.carryOn[selectedOfferData.flightDetails.baggageAllowance.inbound.carryOn].image"
                                       class="bp-num-cl-161 h-1rem" />
                                </div>
                                <div class="bp-num-cl-162 font-medium text-xs line-height-1">
                                  <span>{{
                                    selectedOfferData.baggageAllowance.carryOn[selectedOfferData.flightDetails.baggageAllowance.inbound.carryOn].label
                                    }}</span>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- TOTAL PRICE AND SELECT BUTTON -->
                  <div class="bp-num-cl-163 hidden align-items-center">
                    <div class="bp-num-cl-164 col-6 pl-3">
                      <div class="bp-num-cl-165 text-xs">$$$Ukupna cena</div>
                      <div class="bp-num-cl-166 price-per-person-amount text-lg font-semibold">
                        <FormattedCurrency :amount="selectedOfferData.sidebar.priceInfo.totalPrice.amount"
                                           :currency="selectedOfferData.sidebar.priceInfo.totalPrice.currency"
                                           :currencyPosition="selectedOfferData.sidebar.priceInfo.totalPrice.currencyPosition" />
                      </div>
                    </div>
                    <div class="bp-num-cl-167 col-6 pr-3">
                      <Button class="bp-num-cl-168 col-12 pick-flight flex justify-content-center">
                        {{ flightBookData.content.pageLabels.button }}
                      </Button>
                    </div>
                  </div>
                </div>

              </AccordionTab>
            </Accordion>
            <Card class="bp-num-cl-169 col-12 mt-3 tariff-card"
                  v-if="flightBookData.content.priceClasses.length">
              <template #header>
                <div class="bp-num-cl-170 grid">
                  <div class="bp-num-cl-171 col-12 p-3 text-xl line-height-1">
                    {{ flightBookData.content.pageLabels.priceClasses.headline }}
                  </div>
                </div>
              </template>
              <template #content>
                <div class="bp-num-cl-172 grid">
                  <div class="bp-num-cl-173 col-12 p-2 text-lg">
                    {{ flightBookData.content.pageLabels.priceClasses.subtitle }}
                  </div>
                </div>
                <div class="bp-num-cl-174 flex flex-wrap justify-content-center price-classes">
                  <div v-for="(priceClass, index) in flightBookData.content.priceClasses"
                       class="bp-num-cl-175 col-12 xl:col-4 price-class"
                       :class="{hidden: bannedOfferIds.includes(priceClass.offerId)}">
                    <Card class="bp-num-cl-176 cursor-pointer h-full h-full-children"
                          :class="{ 'border-blue-400': priceClass.offerId == selectedOffer }"
                          @click="() => selectedOffer = priceClass.offerId">
                      <template #content
                                class="bp-num-cl-177 h-100">
                        <div class="bp-num-cl-178 flex flex-wrap align-content-between w-12">

                          <!-- outboundinbound -->
                          <!-- <div v-if="priceClass.display.includes('outbound')"> -->
                          <!-- <div class="bp-num-cl-179 flex justify-content-between"> -->
                          <div class="bp-num-cl-180 flex flex-wrap w-12">
                            <div v-if="priceClass.fareRules && priceClass.display.includes('none')"
                                 class="bp-num-cl-201 w-12 text-center">
                              <Button class="bp-num-cl-202 bg-transparent bodrer-0 text-blue-400 p-0 m-0"
                                      @click.stop="toggleFareRulesDialog(index)">
                                {{ flightBookData.content.pageLabels.sidebar.fareRules }}
                              </Button>
                              <Dialog :header="flightBookData.content.pageLabels.sidebar.fareRules"
                                      v-model:visible="fareRulesDialogVisible[priceClass.offerId]"
                                      class="bp-num-cl-203 fare-rules-modal shadow-5"
                                      :modal="true">
                                <div>
                                  <div
                                       v-html="priceClass.fareRules.replaceAll('courier', '').replaceAll('\<div>', '\<div class=\'justify-content-beteween\'>')">
                                  </div>
                                </div>
                              </Dialog>
                            </div>
                            <div
                                 v-if="priceClass.display.includes('outbound') || priceClass.display.includes('joined') || priceClass.display.includes('separated')">
                              <div
                                   class="bp-num-cl-181 card-content-inside flex flex-wrap align-content-between h-full">
                                <div class="bp-num-cl-182 w-12">
                                  <div v-if="priceClass.display.includes('outbound')">{{
                                    flightBookData.content.pageLabels.priceClasses.display.outbound }}</div>
                                  <div v-if="priceClass.display.includes('separated')">{{
                                    flightBookData.content.pageLabels.priceClasses.display.outbound }}</div>
                                  <div v-if="priceClass.display.includes('joined')">{{
                                    flightBookData.content.pageLabels.priceClasses.display.joined }}</div>
                                  <div class="bp-num-cl-183 font-semibold pb-2 pt-1">
                                    {{ priceClass.outbound && priceClass.outbound.name }}
                                  </div>
                                </div>
                                <div class="bp-num-cl-184 w-12">
                                  <div class="bp-num-cl-185 pb-2 perk-container">
                                    <div class="bp-num-cl-186 w-12 p-0"
                                         v-for="perk in priceClass.outbound.items">
                                      <div class="bp-num-cl-187 flex align-items-center h-2rem -my-1">
                                        <div class="bp-num-cl-188 flex align-items-center justify-content-center pr-2">
                                          <img :src="perk.image"
                                               class="bp-num-cl-189 perks-image" />
                                        </div>
                                        <div class="bp-num-cl-190 font-medium text-xs">
                                          <span>
                                            {{ perk.label }}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                                 v-if="priceClass.display.includes('inbound') || priceClass.display.includes('separated')">
                              <div
                                   class="bp-num-cl-191 card-content-inside flex flex-wrap align-content-between h-full">
                                <div class="bp-num-cl-192 w-12">
                                  <div>
                                    {{flightBookData.content.pageLabels.inbound}}
                                  </div>
                                  <div class="bp-num-cl-193 font-semibold pb-2 pt-1">
                                    {{ priceClass.inbound && priceClass.inbound.name }}
                                  </div>
                                </div>
                                <div class="bp-num-cl-194 w-12">
                                  <div class="bp-num-cl-195 pb-2 perk-container">
                                    <div class="bp-num-cl-196 w-12 p-0"
                                         v-for="perk in priceClass.inbound.items">
                                      <div class="bp-num-cl-197 flex align-items-center h-2rem -my-1">
                                        <div class="bp-num-cl-198 flex align-items-center justify-content-center pr-2">
                                          <img :src="perk.image"
                                               class="bp-num-cl-199 perks-image" />
                                        </div>
                                        <div class="bp-num-cl-200 font-medium text-xs">
                                          <span>
                                            {{ perk.label }}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="priceClass.fareRules && !priceClass.display.includes('none')"
                                 class="bp-num-cl-201 w-12 text-center">
                              <Button class="bp-num-cl-202 bg-transparent bodrer-0 text-blue-400 p-0 m-0"
                                      @click.stop="toggleFareRulesDialog(index)">
                                {{ flightBookData.content.pageLabels.sidebar.fareRules }}
                              </Button>
                              <Dialog :header="flightBookData.content.pageLabels.sidebar.fareRules"
                                      v-model:visible="fareRulesDialogVisible[priceClass.offerId]"
                                      class="bp-num-cl-203 fare-rules-modal shadow-5"
                                      :modal="true">
                                <div>
                                  <div
                                       v-html="priceClass.fareRules.replaceAll('courier', '').replaceAll('\<div>', '\<div class=\'justify-content-beteween\'>')">
                                  </div>
                                </div>
                              </Dialog>
                            </div>
                          </div>
                          <div class="bp-num-cl-204 w-12">
                            <div class="bp-num-cl-205 flex justify-content-center w-12 font-semibold text-lg prix"
                                 v-if="flightBookData.content.offers[index]">
                              <FormattedCurrency :amount="flightBookData.content.offers[index].sidebar.priceInfo.totalPrice.amount"
                                                 :currency="flightBookData.content.offers[index].sidebar.priceInfo.totalPrice.currency"
                                                 :currencyPosition="flightBookData.content.offers[index].sidebar.priceInfo.totalPrice.currencyPosition" />
                            </div>
                          </div>
                        </div>

                      </template>
                    </Card>
                  </div>
                </div>
              </template>
            </Card>
            <Accordion class="bp-num-cl-206 col-12 mt-3"
                       v-model:activeIndex="baggageAccordionActiveIndex">
              <AccordionTab :header="flightBookData.content.pageLabels.baggage">
                <div>
                  <div class="bp-num-cl-207 p-3">
                    <div v-if="flightBookData.content.offers[selectedOffer-1].baggageAllowance">
                      <div v-if="flightBookData.content.offers[selectedOffer-1].baggageAllowance.outbound">
                        <div class="bp-num-cl-208 text-lg">
                          {{flightBookData.content.pageLabels.outbound}}
                        </div>
                        <div v-for="leg in flightBookData.content.offers[selectedOffer-1].baggageAllowance.outbound"
                             class="bp-num-cl-209 pt-2">
                          <div class="bp-num-cl-210 flex">
                            <div class="bp-num-cl-211 pr-1">
                              {{flightBookData.content.airports[leg.departure].city}}
                            </div>
                            <div class="bp-num-cl-212 text-gray-650 pl-1">
                              {{flightBookData.content.airports[leg.departure].code}}
                            </div>
                            <div class="bp-num-cl-213 px-1">
                              {{' - '}}
                            </div>
                            <div class="bp-num-cl-214 px-1">
                              {{flightBookData.content.airports[leg.arrival].city}}
                            </div>
                            <div class="bp-num-cl-215 text-gray-650 pl-1">
                              {{flightBookData.content.airports[leg.arrival].code}}
                            </div>
                          </div>
                          <div class="bp-num-cl-216 grid"
                               v-for="(ageGroup, key) in leg.travelers">
                            <div class="bp-num-cl-217 col-12 xl:col-2 pb-0 xl:pb-2 px-0 flex align-items-end">
                              <div class="bp-num-cl-218 line-height-1">
                                {{flightBookData.content.pageLabels.form[key]}}
                              </div>
                            </div>
                            <div class="bp-num-cl-219 col-6 xl:col-5 flex align-items-end">
                              <img :src="ageGroup.checked.image"
                                   class="bp-num-cl-220 h-2rem" />
                              <div class="bp-num-cl-221 pl-1 line-height-1">
                                {{flightBookData.content.pageLabels.baggageAllowance.checked[ageGroup.checked.id]}}
                              </div>
                            </div>
                            <div class="bp-num-cl-222 col-6 xl:col-5 flex align-items-end">
                              <img :src="ageGroup.carryOn.image"
                                   class="bp-num-cl-223 h-1rem" />
                              <div class="bp-num-cl-224 pl-1 line-height-1">
                                {{flightBookData.content.pageLabels.baggageAllowance.carryOn[ageGroup.carryOn.id]}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="flightBookData.content.offers[selectedOffer-1].baggageAllowance.inbound">
                        <div class="bp-num-cl-225 text-lg pt-3">
                          {{flightBookData.content.pageLabels.inbound}}
                        </div>
                        <div v-for="leg in flightBookData.content.offers[selectedOffer-1].baggageAllowance.inbound"
                             class="bp-num-cl-226 pt-2">
                          <div class="bp-num-cl-227 flex">
                            <div class="bp-num-cl-228 pr-1">
                              {{flightBookData.content.airports[leg.departure].city}}
                            </div>
                            <div class="bp-num-cl-229 text-gray-650 pl-1">
                              {{flightBookData.content.airports[leg.departure].code}}
                            </div>
                            <div class="bp-num-cl-230 px-1">
                              {{' - '}}
                            </div>
                            <div class="bp-num-cl-231 px-1">
                              {{flightBookData.content.airports[leg.arrival].city}}
                            </div>
                            <div class="bp-num-cl-232 text-gray-650 pl-1">
                              {{flightBookData.content.airports[leg.arrival].code}}
                            </div>
                          </div>
                          <div class="bp-num-cl-233 grid"
                               v-for="(ageGroup, key) in leg.travelers">
                            <div class="bp-num-cl-234 col-12 xl:col-2 pb-0 xl:pb-2 px-0 flex align-items-end">
                              <div class="bp-num-cl-235 line-height-1">
                                {{flightBookData.content.pageLabels.form[key]}}
                              </div>
                            </div>
                            <div class="bp-num-cl-236 col-6 xl:col-5 flex align-items-end">
                              <img :src="ageGroup.checked.image"
                                   class="bp-num-cl-237 h-2rem" />
                              <div class="bp-num-cl-238 pl-1 line-height-1">
                                {{flightBookData.content.pageLabels.baggageAllowance.checked[ageGroup.checked.id]}}
                              </div>
                            </div>
                            <div class="bp-num-cl-239 col-6 xl:col-5 flex align-items-end">
                              <img :src="ageGroup.carryOn.image"
                                   class="bp-num-cl-240 h-1rem" />
                              <div class="bp-num-cl-241 pl-1 line-height-1">
                                {{flightBookData.content.pageLabels.baggageAllowance.carryOn[ageGroup.carryOn.id]}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
            <Card class="bp-num-cl-242 col-12 mt-3 traveler-card">
              <template #header>
                <div class="bp-num-cl-243 grid">
                  <div class="bp-num-cl-244 col-12 p-3 text-xl line-height-1">
                    {{ flightBookData.content.pageLabels.sidebar.travelers }}
                  </div>
                </div>
              </template>
              <template #content>
                <div v-for="(travelerGroup, key) in passengers">
                  <div v-for="n in travelerGroup.quantity">
                    <div class="bp-num-cl-245 grid">
                      <div class="bp-num-cl-246 col-12 p-2 border-bottom-1 border-gray-300 psngr">
                        <div>
                          <div class="bp-num-cl-247 text-lg ml-2">
                            {{ n }}. {{ flightBookData.content.pageLabels.form[key] }}
                          </div>
                        </div>
                        <div class="bp-num-cl-248 grid">
                          <div class="bp-num-cl-249 col-12 xl:col-6">
                            <div>
                              {{ flightBookData.content.pageLabels.form.firstName }}
                            </div>
                            <InputText class="bp-num-cl-250 w-12"
                                       id="name"
                                       v-model="passengerData[`${key}${n - 1}`].firstName"
                                       @input="regexify(flightBookData.content.offers[0].form.traveler.fields.names.firstName.allowedCharacterSet, `${key}${n - 1}`, 'firstName')" />
                            <div :class="{'block': passengerData[`${key}${n - 1}`].firstName == '' && checkInvalid}"
                                 v-if="passengerData[`${key}${n - 1}`].firstName == '' && checkInvalid"
                                 class="bp-num-cl-251 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.offers[0].form.traveler.fields.errors.required }}
                            </div>
                            <div :class="{'block': passengerData[`${key}${n - 1}`].firstName != '' && !checkAgainstRegex(passengerData[`${key}${n - 1}`].firstName, flightBookData.content.offers[0].form.traveler.fields.names.firstName.regex) && checkInvalid}"
                                 v-if="passengerData[`${key}${n - 1}`].firstName != '' && !checkAgainstRegex(passengerData[`${key}${n - 1}`].firstName, flightBookData.content.offers[0].form.traveler.fields.names.firstName.regex) && checkInvalid"
                                 class="bp-num-cl-252 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.pageLabels.form.regexError.firstName }}
                            </div>
                            <div :class="{'block': passengerData[`${key}${n - 1}`].firstName != '' && passengerData[`${key}${n - 1}`].lastName != '' && checkInvalid && !checkAgainstRegex(`${passengerData[`${key}${n - 1}`].firstName} ${passengerData[`${key}${n - 1}`].lastName}`, flightBookData.content.offers[0].form.traveler.fields.names.firstNameLastName.regex)}"
                                 v-if="passengerData[`${key}${n - 1}`].firstName != '' && passengerData[`${key}${n - 1}`].lastName != '' && checkInvalid && !checkAgainstRegex(`${passengerData[`${key}${n - 1}`].firstName} ${passengerData[`${key}${n - 1}`].lastName}`, flightBookData.content.offers[0].form.traveler.fields.names.firstNameLastName.regex)"
                                 class="bp-num-cl-253 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.pageLabels.form.regexError.firstNameLastName }}
                            </div>
                          </div>
                          <div class="bp-num-cl-254 col-12 xl:col-6">
                            <div>
                              {{ flightBookData.content.pageLabels.form.lastName }}
                            </div>
                            <InputText class="bp-num-cl-255 w-12"
                                       id="surname"
                                       v-model="passengerData[`${key}${n - 1}`].lastName"
                                       @input="regexify(flightBookData.content.offers[0].form.traveler.fields.names.lastName.allowedCharacterSet, `${key}${n - 1}`, 'lastName')" />
                            <div :class="{'block': passengerData[`${key}${n - 1}`].lastName == '' && checkInvalid}"
                                 v-if="passengerData[`${key}${n - 1}`].lastName == '' && checkInvalid"
                                 class="bp-num-cl-256 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.offers[0].form.traveler.fields.errors.required }}
                            </div>
                            <div :class="{'block': passengerData[`${key}${n - 1}`].lastName != '' && !checkAgainstRegex(passengerData[`${key}${n - 1}`].lastName, flightBookData.content.offers[0].form.traveler.fields.names.lastName.regex) && checkInvalid}"
                                 v-if="passengerData[`${key}${n - 1}`].lastName != '' && !checkAgainstRegex(passengerData[`${key}${n - 1}`].lastName, flightBookData.content.offers[0].form.traveler.fields.names.lastName.regex) && checkInvalid"
                                 class="bp-num-cl-257 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.pageLabels.form.regexError.lastName }}
                            </div>
                          </div>
                          <div class="bp-num-cl-258 col-12"
                               :class="{'hidden': key != 'ADT', 'xl:col-3': key == 'ADT'}">
                            <div>
                              {{ flightBookData.content.pageLabels.form.title }}
                            </div>
                            <Dropdown :panelClass="'dropdown-field'"
                                      class="bp-num-cl-259 w-12"
                                      v-model="passengerData[`${key}${n - 1}`].title"
                                      :options="flightBookData.content.offers[0].form.traveler.fields.titles"
                                      optionLabel="label"
                                      optionValue="value" />
                            <div :class="{'block': !passengerData[`${key}${n - 1}`].title && checkInvalid}"
                                 v-if="!passengerData[`${key}${n - 1}`].title && checkInvalid"
                                 class="bp-num-cl-260 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.offers[0].form.traveler.fields.errors.required }}
                            </div>
                          </div>
                          <div class="bp-num-cl-258 col-12"
                               :class="{'xl:col-6': key != 'ADT', 'xl:col-3': key == 'ADT'}">
                            <div>
                              {{ flightBookData.content.pageLabels.form.gender }}
                            </div>
                            <Dropdown :panelClass="'dropdown-field'"
                                      class="bp-num-cl-259 w-12"
                                      v-model="passengerData[`${key}${n - 1}`].gender"
                                      :options="flightBookData.content.offers[0].form.traveler.fields.genders"
                                      optionLabel="label"
                                      optionValue="value" />
                            <div :class="{'block': !passengerData[`${key}${n - 1}`].gender && checkInvalid}"
                                 v-if="!passengerData[`${key}${n - 1}`].gender && checkInvalid"
                                 class="bp-num-cl-260 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.offers[0].form.traveler.fields.errors.required }}
                            </div>
                          </div>
                          <div class="bp-num-cl-261 col-12 xl:col-6">
                            <div>
                              {{ flightBookData.content.pageLabels.form.birthdate }}
                            </div>
                            <div class="bp-num-cl-262 grid">
                              <InputText placeholder="DD"
                                         class="bp-num-cl-263 col-4 border-noround-right"
                                         :maxlength="2"
                                         :id="`dayinput${key}${n - 1}`"
                                         v-model="passengerData[`${key}${n - 1}`].birthdate.d"
                                         @input="checkDay(`${key}${n - 1}`, 'birthdate', key)"
                                         @blur="addLeadingZero(`${key}${n - 1}`, 'birthdate', key, 'd')" />
                              <InputText placeholder="MM"
                                         :maxlength="2"
                                         class="bp-num-cl-264 col-4 border-noround border-right-white border-left-white"
                                         :id="`monthinput${key}${n - 1}`"
                                         v-model="passengerData[`${key}${n - 1}`].birthdate.m"
                                         @input="checkMonth(`${key}${n - 1}`, 'birthdate', key)"
                                         @blur="addLeadingZero(`${key}${n - 1}`, 'birthdate', key, 'm')" />
                              <InputText placeholder="YYYY"
                                         class="bp-num-cl-265 col-4 border-noround-left"
                                         :maxlength="4"
                                         :id="`yearinput${key}${n - 1}`"
                                         v-model="passengerData[`${key}${n - 1}`].birthdate.y"
                                         @input="checkYear(`${key}${n - 1}`, 'birthdate', key)" />
                            </div>
                            <div :class="{'block': (!passengerData[`${key}${n - 1}`].firstName || !passengerData[`${key}${n - 1}`].birthdate.y || !passengerData[`${key}${n - 1}`].birthdate.m || !passengerData[`${key}${n - 1}`].birthdate.d) && checkInvalid}"
                                 v-if="(!passengerData[`${key}${n - 1}`].birthdate.y || !passengerData[`${key}${n - 1}`].birthdate.m || !passengerData[`${key}${n - 1}`].birthdate.d) && checkInvalid"
                                 class="bp-num-cl-266 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.offers[0].form.traveler.fields.errors.required }}
                            </div>
                            <div :class="{'block': (!passengerData[`${key}${n - 1}`].firstName || !isDate(passengerData[`${key}${n - 1}`].birthdate, key, 'birthdate')) && checkInvalid}"
                                 v-if="(passengerData[`${key}${n - 1}`].birthdate.y && passengerData[`${key}${n - 1}`].birthdate.m && passengerData[`${key}${n - 1}`].birthdate.d && !isDate(passengerData[`${key}${n - 1}`].birthdate, key, 'birthdate')) && checkInvalid"
                                 class="bp-num-cl-267 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ 'Neispravan datum' }}
                            </div>
                            <div :class="{'block': (!passengerData[`${key}${n - 1}`].firstName || birthdateOverOrUnder(key, passengerData[`${key}${n - 1}`].birthdate, flightBookData.content.offers[0].form.traveler.fields.birthdates[key].minDate, flightBookData.content.offers[0].form.traveler.fields.birthdates[key].maxDate) == 'over') && checkInvalid}"
                                 v-if="birthdateOverOrUnder(key, passengerData[`${key}${n - 1}`].birthdate, flightBookData.content.offers[0].form.traveler.fields.birthdates[key].minDate, flightBookData.content.offers[0].form.traveler.fields.birthdates[key].maxDate) == 'over' && checkInvalid"
                                 class="bp-num-cl-268 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.offers[0].form.traveler.fields.errors.ageOver }}
                            </div>
                            <div :class="{'block': (!passengerData[`${key}${n - 1}`].firstName || birthdateOverOrUnder(key, passengerData[`${key}${n - 1}`].birthdate, flightBookData.content.offers[0].form.traveler.fields.birthdates[key].minDate, flightBookData.content.offers[0].form.traveler.fields.birthdates[key].maxDate) == 'under') && checkInvalid}"
                                 v-if="birthdateOverOrUnder(key, passengerData[`${key}${n - 1}`].birthdate, flightBookData.content.offers[0].form.traveler.fields.birthdates[key].minDate, flightBookData.content.offers[0].form.traveler.fields.birthdates[key].maxDate) == 'under' && checkInvalid"
                                 class="bp-num-cl-269 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.offers[0].form.traveler.fields.errors.ageUnder }}
                            </div>
                          </div>
                          <div v-if="flightBookData.content.offers[0].form.traveler.fields.passport"
                               class="bp-num-cl-270 col-12 xl:col-6">
                            <div>
                              {{ flightBookData.content.pageLabels.form.passport.passportCountry }}
                            </div>
                            <Dropdown :panelClass="'dropdown-field'"
                                      class="bp-num-cl-271 w-12"
                                      :id="`passportCountry${key}${n - 1}`"
                                      v-model="passengerData[`${key}${n - 1}`].passportCountry"
                                      :options="passportCountries"
                                      optionLabel="label"
                                      optionValue="value" />
                            <div :class="{'block': passengerData[`${key}${n - 1}`].passportCountry == '' && checkInvalid}"
                                 v-if="passengerData[`${key}${n - 1}`].passportCountry == '' && checkInvalid"
                                 class="bp-num-cl-272 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.offers[0].form.traveler.fields.errors.required }}
                            </div>
                          </div>
                          <div v-if="flightBookData.content.offers[0].form.traveler.fields.passport"
                               class="bp-num-cl-273 col-12 xl:col-6">
                            <div>
                              {{ flightBookData.content.pageLabels.form.passport.number }}
                            </div>
                            <InputText class="bp-num-cl-274 w-12"
                                       id="passportno"
                                       v-model="passengerData[`${key}${n - 1}`].passportNumber"
                                       @input="regexify(flightBookData.content.offers[0].form.traveler.fields.passport.number.allowedCharacterSet, `${key}${n - 1}`, 'passportNumber')" />
                            <div :class="{'block': passengerData[`${key}${n - 1}`].passportNumber == '' && checkInvalid}"
                                 v-if="passengerData[`${key}${n - 1}`].passportNumber == '' && checkInvalid"
                                 class="bp-num-cl-275 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.offers[0].form.traveler.fields.errors.required }}
                            </div>
                            <div :class="{'block': passengerData[`${key}${n - 1}`].passportNumber != '' && !checkAgainstRegex(passengerData[`${key}${n - 1}`].passportNumber, flightBookData.content.offers[0].form.traveler.fields.passport.number.regex) && checkInvalid}"
                                 v-if="passengerData[`${key}${n - 1}`].passportNumber != '' && !checkAgainstRegex(passengerData[`${key}${n - 1}`].passportNumber, flightBookData.content.offers[0].form.traveler.fields.passport.number.regex) && checkInvalid"
                                 class="bp-num-cl-276 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.pageLabels.form.regexError.passportNumber }}
                            </div>
                          </div>
                          <div v-if="flightBookData.content.offers[0].form.traveler.fields.passport"
                               class="bp-num-cl-277 col-12 xl:col-6">
                            <div>
                              {{ flightBookData.content.pageLabels.form.passport.citizenship }}
                            </div>
                            <Dropdown :panelClass="'dropdown-field'"
                                      class="bp-num-cl-278 w-12"
                                      :id="`passportCountry${key}${n - 1}`"
                                      v-model="passengerData[`${key}${n - 1}`].citizenship"
                                      :options="passportCountries"
                                      optionLabel="label"
                                      optionValue="value" />
                            <div :class="{'block': !passengerData[`${key}${n - 1}`].citizenship && checkInvalid}"
                                 v-if="!passengerData[`${key}${n - 1}`].citizenship && checkInvalid"
                                 class="bp-num-cl-279 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.offers[0].form.traveler.fields.errors.required }}
                            </div>
                          </div>
                          <div v-if="flightBookData.content.offers[0].form.traveler.fields.passport"
                               class="bp-num-cl-280 col-12 xl:col-6">
                            <div>
                              {{ flightBookData.content.pageLabels.form.passport.expireDate }}
                            </div>
                            <div class="bp-num-cl-281 grid">
                              <InputText placeholder="DD"
                                         class="bp-num-cl-282 col-4 border-noround-right"
                                         :maxlength="2"
                                         :id="`dayinput${key}${n - 1}`"
                                         v-model="passengerData[`${key}${n - 1}`].passportDate.d"
                                         @input="checkDay(`${key}${n - 1}`, 'passportDate', key)"
                                         @blur="addLeadingZero(`${key}${n - 1}`, 'passportDate', key, 'd')" />
                              <InputText placeholder="MM"
                                         :maxlength="2"
                                         class="bp-num-cl-283 col-4 border-noround border-right-white border-left-white"
                                         :id="`monthinput${key}${n - 1}`"
                                         v-model="passengerData[`${key}${n - 1}`].passportDate.m"
                                         @input="checkMonth(`${key}${n - 1}`, 'passportDate', key)"
                                         @blur="addLeadingZero(`${key}${n - 1}`, 'passportDate', key, 'm')" />
                              <InputText placeholder="YYYY"
                                         class="bp-num-cl-284 col-4 border-noround-left"
                                         :maxlength="4"
                                         :id="`yearinput${key}${n - 1}`"
                                         v-model="passengerData[`${key}${n - 1}`].passportDate.y"
                                         @input="checkYear(`${key}${n - 1}`, 'passportDate', key)" />
                            </div>
                            <div :class="{'block': (!passengerData[`${key}${n - 1}`].firstName || !passengerData[`${key}${n - 1}`].passportDate.y || !passengerData[`${key}${n - 1}`].passportDate.m || !passengerData[`${key}${n - 1}`].passportDate.d) && checkInvalid}"
                                 v-if="(!passengerData[`${key}${n - 1}`].passportDate.y || !passengerData[`${key}${n - 1}`].passportDate.m || !passengerData[`${key}${n - 1}`].passportDate.d) && checkInvalid"
                                 class="bp-num-cl-285 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.offers[0].form.traveler.fields.errors.required }}
                            </div>
                            <div :class="{'block': (!passengerData[`${key}${n - 1}`].firstName || !isDate(passengerData[`${key}${n - 1}`].passportDate, key, 'passportDate')) && checkInvalid}"
                                 v-if="(passengerData[`${key}${n - 1}`].passportDate.y && passengerData[`${key}${n - 1}`].passportDate.m && passengerData[`${key}${n - 1}`].passportDate.d && !isDate(passengerData[`${key}${n - 1}`].passportDate, key, 'passportDate')) && checkInvalid"
                                 class="bp-num-cl-286 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ 'Neispravan datum' }}
                            </div>
                            <div :class="{'block': (!passengerData[`${key}${n - 1}`].firstName || birthdateOverOrUnder(key, passengerData[`${key}${n - 1}`].passportDate, flightBookData.content.offers[0].form.traveler.fields.passport.expireDate.minDate, flightBookData.content.offers[0].form.traveler.fields.passport.expireDate.maxDate) == 'over') && checkInvalid}"
                                 v-if="birthdateOverOrUnder(key, passengerData[`${key}${n - 1}`].passportDate, flightBookData.content.offers[0].form.traveler.fields.passport.expireDate.minDate, flightBookData.content.offers[0].form.traveler.fields.passport.expireDate.maxDate) == 'over' && checkInvalid"
                                 class="bp-num-cl-287 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.offers[0].form.traveler.fields.passport.errors.invalidExpireDate
                              }}
                            </div>
                            <div :class="{'block': (!passengerData[`${key}${n - 1}`].firstName || birthdateOverOrUnder(key, passengerData[`${key}${n - 1}`].passportDate, flightBookData.content.offers[0].form.traveler.fields.passport.expireDate.minDate, flightBookData.content.offers[0].form.traveler.fields.passport.expireDate.maxDate) == 'under') && checkInvalid}"
                                 v-if="birthdateOverOrUnder(key, passengerData[`${key}${n - 1}`].passportDate, flightBookData.content.offers[0].form.traveler.fields.passport.expireDate.minDate, flightBookData.content.offers[0].form.traveler.fields.passport.expireDate.maxDate) == 'under' && checkInvalid"
                                 class="bp-num-cl-288 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                              {{ flightBookData.content.offers[0].form.traveler.fields.passport.errors.invalidExpireDate
                              }}
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Card>

            <!-- NEWWW -->
            <Card class="bp-num-cl-289 col-12 border-1 border-gray-300 mt-3">
              <template #header>
                <div class="bp-num-cl-290 grid">
                  <div class="bp-num-cl-291 col-12 text-lg p-3">
                    {{flightBookData.content.pageLabels.form.buyer.headline}}
                  </div>
                </div>
              </template>
              <template #content>
                <TabView class="bp-num-cl-292 buyer-data"
                         v-model:activeIndex="tabViewActiveIndex">
                  <TabPanel :header="flightBookData.content.pageLabels.form.buyer.natural">
                    <div class="bp-num-cl-293 grid">
                      <div class="bp-num-cl-294 col-12 xl:col-6 grid p-0 xl:p-2">
                        <div class="bp-num-cl-295 w-12">
                          <div class="bp-num-cl-296 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.gender}}
                          </div>
                          <Dropdown :name="'sex'"
                                    class="bp-num-cl-297 w-12"
                                    v-model="paymentData.person.gender"
                                    :options="flightBookData.content.offers[0].form.buyer.fields.genders"
                                    optionLabel="label"
                                    optionValue="value" />
                          <div :class="{'block': paymentData.person.gender == '' && checkInvalid}"
                               v-if="paymentData.person.gender == '' && checkInvalid"
                               class="bp-num-cl-298 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                        </div>
                        <div class="bp-num-cl-299 w-12">
                          <div class="bp-num-cl-300 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.firstName}}
                          </div>
                          <input name="firstName"
                                 type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-301 w-12"
                                 v-model="paymentData.person.firstName"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.names.firstName.allowedCharacterSet, 'person', 'firstName', $event)" />
                          <div :class="{'block': paymentData.person.firstName == '' && checkInvalid}"
                               v-if="paymentData.person.firstName == '' && checkInvalid"
                               class="bp-num-cl-302 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.person.firstName != '' && !checkAgainstRegex(paymentData.person.firstName, flightBookData.content.offers[0].form.buyer.fields.names.firstName.regex) && checkInvalid}"
                               v-if="paymentData.person.firstName != '' && !checkAgainstRegex(paymentData.person.firstName, flightBookData.content.offers[0].form.buyer.fields.names.firstName.regex) && checkInvalid"
                               class="bp-num-cl-303 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.firstName }}
                          </div>
                        </div>
                        <div class="bp-num-cl-304 w-12">
                          <div class="bp-num-cl-305 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.lastName}}
                          </div>
                          <input name="lastName"
                                 type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-306 w-12"
                                 v-model="paymentData.person.lastName"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.names.lastName.allowedCharacterSet, 'person', 'lastName', $event)" />
                          <div :class="{'block': paymentData.person.lastName == '' && checkInvalid}"
                               v-if="paymentData.person.lastName == '' && checkInvalid"
                               class="bp-num-cl-307 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.person.lastName != '' && !checkAgainstRegex(paymentData.person.lastName, flightBookData.content.offers[0].form.buyer.fields.names.lastName.regex) && checkInvalid}"
                               v-if="paymentData.person.lastName != '' && !checkAgainstRegex(paymentData.person.lastName, flightBookData.content.offers[0].form.buyer.fields.names.lastName.regex) && checkInvalid"
                               class="bp-num-cl-308 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.lastName }}
                          </div>
                        </div>
                        <div class="bp-num-cl-309 w-12">
                          <div class="bp-num-cl-310 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.email}}
                          </div>
                          <input name="email"
                                 type="email"
                                 class="bp-num-cl-311 w-12"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 v-model="paymentData.person.email"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.email.allowedCharacterSet, 'person', 'email', $event)" />
                          <div :class="{'block': paymentData.person.email == '' && checkInvalid}"
                               v-if="paymentData.person.email == '' && checkInvalid"
                               class="bp-num-cl-312 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.person.email != '' && !checkAgainstRegex(paymentData.person.email, flightBookData.content.offers[0].form.buyer.fields.email.regex) && checkInvalid}"
                               v-if="paymentData.person.email != '' && !checkAgainstRegex(paymentData.person.email, flightBookData.content.offers[0].form.buyer.fields.email.regex) && checkInvalid"
                               class="bp-num-cl-313 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.email }}
                          </div>
                        </div>
                        <div class="bp-num-cl-314 w-12">
                          <div class="bp-num-cl-315 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.contactNumber}}
                          </div>
                          <input name="tel"
                                 type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-316 w-12"
                                 v-model="paymentData.person.contactNumber"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.contactNumber.allowedCharacterSet, 'person', 'contactNumber', $event)" />
                          <div :class="{'block': paymentData.person.contactNumber == '' && checkInvalid}"
                               v-if="paymentData.person.contactNumber == '' && checkInvalid"
                               class="bp-num-cl-317 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.person.contactNumber != '' && !checkAgainstRegex(paymentData.person.contactNumber, flightBookData.content.offers[0].form.buyer.fields.contactNumber.regex) && checkInvalid}"
                               v-if="paymentData.person.contactNumber != '' && !checkAgainstRegex(paymentData.person.contactNumber, flightBookData.content.offers[0].form.buyer.fields.contactNumber.regex) && checkInvalid"
                               class="bp-num-cl-318 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.contactNumber }}
                          </div>
                        </div>
                        <div class="bp-num-cl-319 w-12">
                          <div class="bp-num-cl-320 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.streetAddress}}
                          </div>
                          <input name="streetAddress"
                                 type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-321 w-12"
                                 v-model="paymentData.person.streetAddress"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.streetAddress.allowedCharacterSet, 'person', 'streetAddress', $event)" />
                          <div :class="{'block': paymentData.person.streetAddress == '' && checkInvalid}"
                               v-if="paymentData.person.streetAddress == '' && checkInvalid"
                               class="bp-num-cl-322 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.person.streetAddress != '' && !checkAgainstRegex(paymentData.person.streetAddress, flightBookData.content.offers[0].form.buyer.fields.streetAddress.regex) && checkInvalid}"
                               v-if="paymentData.person.streetAddress != '' && !checkAgainstRegex(paymentData.person.streetAddress, flightBookData.content.offers[0].form.buyer.fields.streetAddress.regex) && checkInvalid"
                               class="bp-num-cl-323 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.streetAddress }}
                          </div>
                        </div>
                      </div>
                      <div class="bp-num-cl-324 col-12 xl:col-6 grid p-0 xl:p-2">
                        <div class="bp-num-cl-325 w-12">
                          <div class="bp-num-cl-326 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.postalCode}}
                          </div>
                          <input name="postalCode"
                                 type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-327 w-12"
                                 v-model="paymentData.person.postalCode"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.postalCode.allowedCharacterSet, 'person', 'postalCode', $event)" />
                          <div :class="{'block': paymentData.person.postalCode == '' && checkInvalid}"
                               v-if="paymentData.person.postalCode == '' && checkInvalid"
                               class="bp-num-cl-328 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.person.postalCode != '' && !checkAgainstRegex(paymentData.person.postalCode, flightBookData.content.offers[0].form.buyer.fields.postalCode.regex) && checkInvalid}"
                               v-if="paymentData.person.postalCode != '' && !checkAgainstRegex(paymentData.person.postalCode, flightBookData.content.offers[0].form.buyer.fields.postalCode.regex) && checkInvalid"
                               class="bp-num-cl-329 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.postalCode }}
                          </div>
                        </div>
                        <div class="bp-num-cl-330 w-12">
                          <div class="bp-num-cl-331 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.city}}
                          </div>
                          <input name="city"
                                 type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-332 w-12"
                                 v-model="paymentData.person.city"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.city.allowedCharacterSet, 'person', 'city', $event)" />
                          <div :class="{'block': paymentData.person.city == '' && checkInvalid}"
                               v-if="paymentData.person.city == '' && checkInvalid"
                               class="bp-num-cl-333 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.person.city != '' && !checkAgainstRegex(paymentData.person.city, flightBookData.content.offers[0].form.buyer.fields.city.regex) && checkInvalid}"
                               v-if="paymentData.person.city != '' && !checkAgainstRegex(paymentData.person.city, flightBookData.content.offers[0].form.buyer.fields.city.regex) && checkInvalid"
                               class="bp-num-cl-334 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.city }}
                          </div>
                        </div>
                        <div class="bp-num-cl-335 w-12">
                          <div class="bp-num-cl-336 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.country}}
                          </div>
                          <!-- <input type="text" :class="['p-inputtext p-component', { 'p-filled': false }]" class="bp-num-cl-337 w- model="paymentData.person.country">
                          
                          </I12" @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.country.allowedCharacterSet, 'person', 'country', $event)" vnputText> -->
                          <Dropdown class="bp-num-cl-338 w-12"
                                    v-model="paymentData.person.country"
                                    :options="passportCountriesBuyer"
                                    optionLabel="label"
                                    optionValue="value" />
                          <div :class="{'block': paymentData.person.country == '' && checkInvalid}"
                               v-if="paymentData.person.country == '' && checkInvalid"
                               class="bp-num-cl-339 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                        </div>
                        <div class="bp-num-cl-340 w-12">
                          <div class="bp-num-cl-341 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.note}}
                          </div>
                          <input type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-342 w-12"
                                 v-model="paymentData.person.note"
                                 style="height: 190px;" />
                          <!-- <div :class="{'block': paymentData.person.note == '' && checkInvalid}" v-if="paymentData.person.note == '' && checkInvalid" class="bp-num-cl-343 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                                                       {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          
                          
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel :header="flightBookData.content.pageLabels.form.buyer.legal">
                    <div class="bp-num-cl-344 grid">
                      <div class="bp-num-cl-345 col-12 xl:col-6 grid p-0 xl:p-2">
                        <div class="bp-num-cl-346 w-12">
                          <div class="bp-num-cl-347 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.companyName}}
                          </div>
                          <input type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-348 w-12"
                                 v-model="paymentData.company.companyName"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.companyName.allowedCharacterSet, 'company', 'companyName', $event)" />
                          <div :class="{'block': paymentData.company.companyName == '' && checkInvalid}"
                               v-if="paymentData.company.companyName == '' && checkInvalid"
                               class="bp-num-cl-349 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.company.companyName != '' && !checkAgainstRegex(paymentData.company.companyName, flightBookData.content.offers[0].form.buyer.fields.companyName.regex) && checkInvalid}"
                               v-if="paymentData.company.companyName != '' && !checkAgainstRegex(paymentData.company.companyName, flightBookData.content.offers[0].form.buyer.fields.companyName.regex) && checkInvalid"
                               class="bp-num-cl-350 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.companyName }}
                          </div>
                        </div>
                        <div class="bp-num-cl-351 w-12">
                          <div class="bp-num-cl-352 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.companyVAT}}
                          </div>
                          <input type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-353 w-12"
                                 v-model="paymentData.company.companyVAT"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.companyVAT.allowedCharacterSet, 'company', 'companyVAT', $event)" />
                          <div :class="{'block': paymentData.company.companyVAT == '' && checkInvalid}"
                               v-if="paymentData.company.companyVAT == '' && checkInvalid"
                               class="bp-num-cl-354 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.company.companyVAT != '' && !checkAgainstRegex(paymentData.company.companyVAT, flightBookData.content.offers[0].form.buyer.fields.companyVAT.regex) && checkInvalid}"
                               v-if="paymentData.company.companyVAT != '' && !checkAgainstRegex(paymentData.company.companyVAT, flightBookData.content.offers[0].form.buyer.fields.companyVAT.regex) && checkInvalid"
                               class="bp-num-cl-355 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.companyVAT }}
                          </div>
                        </div>
                        <div class="bp-num-cl-356 w-12">
                          <div class="bp-num-cl-357 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.gender}}
                          </div>
                          <Dropdown class="bp-num-cl-358 w-12"
                                    v-model="paymentData.company.gender"
                                    :options="flightBookData.content.offers[0].form.buyer.fields.genders"
                                    optionLabel="label"
                                    optionValue="value" />
                          <div :class="{'block': paymentData.company.gender == '' && checkInvalid}"
                               v-if="paymentData.company.gender == '' && checkInvalid"
                               class="bp-num-cl-359 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                        </div>
                        <div class="bp-num-cl-360 w-12">
                          <div class="bp-num-cl-361 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.firstName}}
                          </div>
                          <input type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-362 w-12"
                                 v-model="paymentData.company.firstName"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.names.firstName.allowedCharacterSet, 'company', 'firstName', $event)" />
                          <div :class="{'block': paymentData.company.firstName == '' && checkInvalid}"
                               v-if="paymentData.company.firstName == '' && checkInvalid"
                               class="bp-num-cl-363 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.company.firstName != '' && !checkAgainstRegex(paymentData.company.firstName, flightBookData.content.offers[0].form.buyer.fields.names.firstName.regex) && checkInvalid}"
                               v-if="paymentData.company.firstName != '' && !checkAgainstRegex(paymentData.company.firstName, flightBookData.content.offers[0].form.buyer.fields.names.firstName.regex) && checkInvalid"
                               class="bp-num-cl-364 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.firstName }}
                          </div>
                        </div>
                        <div class="bp-num-cl-365 w-12">
                          <div class="bp-num-cl-366 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.lastName}}
                          </div>
                          <input type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-367 w-12"
                                 v-model="paymentData.company.lastName"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.names.lastName.allowedCharacterSet, 'company', 'lastName', $event)" />
                          <div :class="{'block': paymentData.company.lastName == '' && checkInvalid}"
                               v-if="paymentData.company.lastName == '' && checkInvalid"
                               class="bp-num-cl-368 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.company.lastName != '' && !checkAgainstRegex(paymentData.company.lastName, flightBookData.content.offers[0].form.buyer.fields.names.lastName.regex) && checkInvalid}"
                               v-if="paymentData.company.lastName != '' && !checkAgainstRegex(paymentData.company.lastName, flightBookData.content.offers[0].form.buyer.fields.names.lastName.regex) && checkInvalid"
                               class="bp-num-cl-369 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.lastName }}
                          </div>
                        </div>
                        <div class="bp-num-cl-370 w-12">
                          <div class="bp-num-cl-371 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.email}}
                          </div>
                          <input type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-372 w-12"
                                 v-model="paymentData.company.email"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.email.allowedCharacterSet, 'company', 'email', $event)" />
                          <div :class="{'block': paymentData.company.email == '' && checkInvalid}"
                               v-if="paymentData.company.email == '' && checkInvalid"
                               class="bp-num-cl-373 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.company.email != '' && !checkAgainstRegex(paymentData.company.email, flightBookData.content.offers[0].form.buyer.fields.email.regex) && checkInvalid}"
                               v-if="paymentData.company.email != '' && !checkAgainstRegex(paymentData.company.email, flightBookData.content.offers[0].form.buyer.fields.email.regex) && checkInvalid"
                               class="bp-num-cl-374 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.email }}
                          </div>
                        </div>
                        <div class="bp-num-cl-375 w-12">
                          <div class="bp-num-cl-376 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.contactNumber}}
                          </div>
                          <input type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-377 w-12"
                                 v-model="paymentData.company.contactNumber"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.contactNumber.allowedCharacterSet, 'company', 'contactNumber', $event)" />
                          <div :class="{'block': paymentData.company.contactNumber == '' && checkInvalid}"
                               v-if="paymentData.company.contactNumber == '' && checkInvalid"
                               class="bp-num-cl-378 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.company.contactNumber != '' && !checkAgainstRegex(paymentData.company.contactNumber, flightBookData.content.offers[0].form.buyer.fields.contactNumber.regex) && checkInvalid}"
                               v-if="paymentData.company.contactNumber != '' && !checkAgainstRegex(paymentData.company.contactNumber, flightBookData.content.offers[0].form.buyer.fields.contactNumber.regex) && checkInvalid"
                               class="bp-num-cl-379 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.contactNumber }}
                          </div>
                        </div>
                      </div>
                      <div class="bp-num-cl-380 col-12 xl:col-6 grid p-0 xl:p-2">
                        <div class="bp-num-cl-381 w-12">
                          <div class="bp-num-cl-382 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.streetAddress}}
                          </div>
                          <input type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-383 w-12"
                                 v-model="paymentData.company.streetAddress"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.streetAddress.allowedCharacterSet, 'company', 'streetAddress', $event)" />
                          <div :class="{'block': paymentData.company.streetAddress == '' && checkInvalid}"
                               v-if="paymentData.company.streetAddress == '' && checkInvalid"
                               class="bp-num-cl-384 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.company.streetAddress != '' && !checkAgainstRegex(paymentData.company.streetAddress, flightBookData.content.offers[0].form.buyer.fields.streetAddress.regex) && checkInvalid}"
                               v-if="paymentData.company.streetAddress != '' && !checkAgainstRegex(paymentData.company.streetAddress, flightBookData.content.offers[0].form.buyer.fields.streetAddress.regex) && checkInvalid"
                               class="bp-num-cl-385 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.streetAddress }}
                          </div>
                        </div>
                        <div class="bp-num-cl-386 w-12">
                          <div class="bp-num-cl-387 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.postalCode}}
                          </div>
                          <input type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-388 w-12"
                                 v-model="paymentData.company.postalCode"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.postalCode.allowedCharacterSet, 'company', 'postalCode', $event)" />
                          <div :class="{'block': paymentData.company.postalCode == '' && checkInvalid}"
                               v-if="paymentData.company.postalCode == '' && checkInvalid"
                               class="bp-num-cl-389 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.company.postalCode != '' && !checkAgainstRegex(paymentData.company.postalCode, flightBookData.content.offers[0].form.buyer.fields.postalCode.regex) && checkInvalid}"
                               v-if="paymentData.company.postalCode != '' && !checkAgainstRegex(paymentData.company.postalCode, flightBookData.content.offers[0].form.buyer.fields.postalCode.regex) && checkInvalid"
                               class="bp-num-cl-390 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.postalCode }}
                          </div>
                        </div>
                        <div class="bp-num-cl-391 w-12">
                          <div class="bp-num-cl-392 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.city}}
                          </div>
                          <input type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-393 w-12"
                                 v-model="paymentData.company.city"
                                 @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.city.allowedCharacterSet, 'company', 'city', $event)" />
                          <div :class="{'block': paymentData.company.city == '' && checkInvalid}"
                               v-if="paymentData.company.city == '' && checkInvalid"
                               class="bp-num-cl-394 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                          <div :class="{'block': paymentData.company.city != '' && !checkAgainstRegex(paymentData.company.city, flightBookData.content.offers[0].form.buyer.fields.city.regex) && checkInvalid}"
                               v-if="paymentData.company.city != '' && !checkAgainstRegex(paymentData.company.city, flightBookData.content.offers[0].form.buyer.fields.city.regex) && checkInvalid"
                               class="bp-num-cl-395 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.city }}
                          </div>
                        </div>
                        <div class="bp-num-cl-396 w-12">
                          <div class="bp-num-cl-397 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.country}}
                          </div>
                          <!-- <input type="text" :class="['p-inputtext p-component', { 'p-filled': false }]" class="bp-num-cl-398 w- model="paymentData.company.country">
                          
                          </I12" @input="regexifyBuyer(flightBookData.content.offers[0].form.buyer.fields.country.allowedCharacterSet, 'company', 'country', $event)" vnputText> -->
                          <Dropdown class="bp-num-cl-399 w-12"
                                    v-model="paymentData.company.country"
                                    :options="passportCountriesBuyer"
                                    optionLabel="label"
                                    optionValue="value" />
                          <div :class="{'block': paymentData.company.country == '' && checkInvalid}"
                               v-if="paymentData.company.country == '' && checkInvalid"
                               class="bp-num-cl-400 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                            {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          </div>
                        </div>
                        <div class="bp-num-cl-401 w-12">
                          <div class="bp-num-cl-402 pt-3">
                            {{flightBookData.content.pageLabels.form.buyer.note}}
                          </div>
                          <input type="text"
                                 :class="['p-inputtext p-component', { 'p-filled': false }]"
                                 class="bp-num-cl-403 w-12"
                                 v-model="paymentData.company.note"
                                 style="height: 190px;" />
                          <!-- <div :class="{'block': paymentData.company.note == '' && checkInvalid}" v-if="paymentData.company.note == '' && checkInvalid" class="bp-num-cl-404 p-error mx-1 lg:mx-0 relative lg:absolute hidden text-xs text-left">
                                                       {{ flightBookData.content.pageLabels.form.buyer.regexError.required }}
                          
                          
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
              </template>
            </Card>

            <!-- NEWWW -->

            <Card class="bp-num-cl-405 col-12 mt-3">
              <template #header>
                <div class="bp-num-cl-406 grid">
                  <div class="bp-num-cl-407 col-12 p-3 text-lg">
                    {{ flightBookData.content.pageLabels.form.payment.headline }}
                  </div>
                </div>
              </template>
              <template #content>
                <div class="bp-num-cl-408 flex align-items-center">
                  <div class="bp-num-cl-409 col-6 xl:col-9 text-lg font-semibold p-0">
                    <FormattedCurrency :amount="selectedOfferData.sidebar.priceInfo.totalPrice.amount"
                                       :currency="selectedOfferData.sidebar.priceInfo.totalPrice.currency"
                                       :currencyPosition="selectedOfferData.sidebar.priceInfo.totalPrice.currencyPosition" />
                  </div>
                  <div class="bp-num-cl-410 col-6 xl:col-3 text-right p-0">
                    <Button @click="proceedToPayment"
                            style="min-width: 165px">
                      <div class="bp-num-cl-411 col-12 p-0 text-white">
                        {{ flightBookData.content.pageLabels.form.payment.button }}
                      </div>
                    </Button>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
        <div class="bp-num-cl-412 hidden xl:block xl:col-3"
             :class="{'relative': !isSidebarFixed}">
          <Card id="side-card"
                class="bp-num-cl-413 side-card"
                :class="{'fixed top-0 mt-2': isSidebarFixed, 'absolute bottom-0 mb-2': isSidebarDockedToBottom}">
            <!-- <Card id="side-card"
            class="bp-num-cl-414 side-card">
           -->
            <template #header>
              <div class="bp-num-cl-415 p-3 text-base line-height-1">
                {{ flightBookData.content.pageLabels.sidebar.headline }}
              </div>
            </template>
            <template #content>
              <div class="bp-num-cl-416 p-0 pb-2">
                <div class="bp-num-cl-417 reservation-sidebar-outbound">
                  <div class="bp-num-cl-418 flex align-items-center ">
                    <img :src="airplaneIcon"
                         class="bp-num-cl-419 autocomplete-icon">
                    <div>
                      {{ flightBookData.content.pageLabels.sidebar.outbound }}
                    </div>
                  </div>
                  <div class="bp-num-cl-420 ">
                    <FlightTime class="bp-num-cl-421 start-time"
                                :airport="selectedOfferData.sidebar.flight.outbound.departure.code"
                                :date="selectedOfferData.sidebar.flight.outbound.departure.date"
                                :time="selectedOfferData.sidebar.flight.outbound.departure.time">
                    </FlightTime>
                  </div>
                  <div v-if="selectedOfferData.sidebar.flight.outbound.stops?.length > 0">
                    <div class="bp-num-cl-422 my-1">
                      <div class="bp-num-cl-423 flex align-items-center">
                        <div class="bp-num-cl-424 col-12 py-1 px-0 text-xs text-gray-400 font-light">
                          {{ flightBookData.content.pageLabels.stopIn }} <span
                                class="bp-num-cl-425 text-gray-400 font-light"
                                v-for="code in selectedOfferData.sidebar.flight.outbound.stops">
                            {{ code }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bp-num-cl-426 ">
                    <FlightTime class="bp-num-cl-427 start-time"
                                :airport="selectedOfferData.sidebar.flight.outbound.arrival.code"
                                :date="selectedOfferData.sidebar.flight.outbound.arrival.date"
                                :time="selectedOfferData.sidebar.flight.outbound.arrival.time">
                    </FlightTime>
                  </div>
                </div>
                <div v-if="selectedOfferData.sidebar.flight.inbound"
                     class="bp-num-cl-428 reservation-sidebar-inbound pt-3">
                  <div class="bp-num-cl-429 flex align-items-center ">
                    <img :src="airplaneIcon"
                         class="bp-num-cl-430 autocomplete-icon">
                    <div>
                      {{ flightBookData.content.pageLabels.sidebar.inbound }}
                    </div>
                  </div>
                  <div class="bp-num-cl-431 ">
                    <FlightTime class="bp-num-cl-432 start-time"
                                :airport="selectedOfferData.sidebar.flight.inbound.departure.code"
                                :date="selectedOfferData.sidebar.flight.inbound.departure.date"
                                :time="selectedOfferData.sidebar.flight.inbound.departure.time">
                    </FlightTime>
                  </div>
                  <div v-if="selectedOfferData.sidebar.flight.inbound.stops?.length > 0">
                    <div class="bp-num-cl-433 my-1">
                      <div class="bp-num-cl-434 flex align-items-center">
                        <div class="bp-num-cl-435 col-12 py-1 px-0 text-xs text-gray-400 font-light">
                          {{ flightBookData.content.pageLabels.stopIn }} <span
                                class="bp-num-cl-436 text-gray-400 font-light"
                                v-for="code in selectedOfferData.sidebar.flight.inbound.stops">
                            {{ code }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bp-num-cl-437 ">
                    <FlightTime class="bp-num-cl-438 start-time"
                                :airport="selectedOfferData.sidebar.flight.inbound.arrival.code"
                                :date="selectedOfferData.sidebar.flight.inbound.arrival.date"
                                :time="selectedOfferData.sidebar.flight.inbound.arrival.time">
                    </FlightTime>
                  </div>
                </div>
              </div>
              <div v-if="flightBookData.content.priceClasses.length"
                   class="bp-num-cl-439 pb-2">
                <div class="bp-num-cl-440 bg-gray-400 h1p">
                   
                </div>
                <div class="bp-num-cl-441 font-sm pt-2"
                     v-if="!selectedOfferSidebarPriceClass.display.includes('none')">
                  {{ flightBookData.content.pageLabels.sidebar.priceClasses }}
                </div>
                <div
                     v-if="selectedOfferSidebarPriceClass && selectedOfferSidebarPriceClass.display.includes('outbound') || selectedOfferSidebarPriceClass.display.includes('joined') || selectedOfferSidebarPriceClass.display.includes('separated')">
                  <div class="bp-num-cl-442 font-sm pt-1">
                    <div v-if="selectedOfferSidebarPriceClass.display.includes('outbound')">{{
                      flightBookData.content.pageLabels.priceClasses.display.outbound }}</div>
                    <div v-if="selectedOfferSidebarPriceClass.display.includes('separated')">{{
                      flightBookData.content.pageLabels.priceClasses.display.outbound }}</div>
                    <div v-if="selectedOfferSidebarPriceClass.display.includes('joined')">{{
                      flightBookData.content.pageLabels.priceClasses.display.joined }}</div>
                  </div>
                  <div class="bp-num-cl-443 font-semibold pb-2 pt-1">
                    {{ selectedOfferSidebarPriceClass?.inbound && selectedOfferSidebarPriceClass.outbound.name }}
                  </div>
                  <div class="bp-num-cl-444 pb-2">
                    <div class="bp-num-cl-445 w-12 p-0"
                         v-for="perk in selectedOfferSidebarPriceClass.outbound.items">
                      <div class="bp-num-cl-446 flex align-items-center h-2rem -my-1">
                        <div class="bp-num-cl-447 flex align-items-center justify-content-center pr-2">
                          <img :src="perk.image"
                               class="bp-num-cl-448 perks-image" />
                        </div>
                        <div class="bp-num-cl-449 font-medium text-xs">
                          <span>
                            {{ perk.label }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                     v-if="selectedOfferSidebarPriceClass && selectedOfferSidebarPriceClass.display.includes('inbound') || selectedOfferSidebarPriceClass.display.includes('separated')">
                  <div class="bp-num-cl-450 font-sm pt-1">
                    {{ flightBookData.content.pageLabels.sidebar.inbound }}
                  </div>
                  <div class="bp-num-cl-451 font-semibold pb-2 pt-1">
                    {{ selectedOfferSidebarPriceClass?.inbound &&
                    selectedOfferSidebarPriceClass.inbound.name }}
                  </div>
                  <div class="bp-num-cl-452 w-12 p-0"
                       v-for="perk in selectedOfferSidebarPriceClass.inbound.items">
                    <div class="bp-num-cl-453 flex align-items-center h-2rem -my-1">
                      <div class="bp-num-cl-454 flex align-items-center justify-content-center pr-2">
                        <img :src="perk.image"
                             class="bp-num-cl-455 perks-image" />
                      </div>
                      <div class="bp-num-cl-456 font-medium text-xs">
                        <span>
                          {{ perk.label }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="selectedOfferData.fareRules">
                  <Button class="bp-num-cl-457 bg-transparent bodrer-0 text-blue-400 p-0 m-0 pt-2"
                          @click="toggleFareRulesSidebarDialog">
                    {{ flightBookData.content.pageLabels.sidebar.fareRules }}
                  </Button>
                  <Dialog :header="flightBookData.content.pageLabels.sidebar.fareRules"
                          v-model:visible="fareRulesSidebarDialogVisible"
                          class="bp-num-cl-458 fare-rules-modal shadow-5"
                          :modal="true">
                    <div>
                      <div v-html="selectedOfferData.fareRules.replaceAll('courier', '')">
                      </div>
                    </div>
                  </Dialog>
                </div>
              </div>
              <div class="bp-num-cl-459 bg-gray-400 h1p">
                 
              </div>
              <div class="bp-num-cl-460 py-2">
                {{ flightBookData.content.pageLabels.sidebar.travelers }}
              </div>
              <div v-for="ageGroup in selectedOfferData.sidebar.priceInfo.travelers">
                <div class="bp-num-cl-461 grid pb-2">
                  <div class="bp-num-cl-462 w-12">
                    {{ flightBookData.content.pageLabels.sidebar[ageGroup.type] }}
                  </div>
                  <div class="bp-num-cl-463 w-6">
                    {{ ageGroup.quantity }}
                    x
                    <FormattedCurrency :amount="ageGroup.amount"
                                       :currency="ageGroup.currency"
                                       :currency-position="ageGroup.currencyPosition" />
                  </div>
                  <div class="bp-num-cl-464 w-6 text-right">
                    <FormattedCurrency :amount="ageGroup.total"
                                       :currency="ageGroup.currency"
                                       :currency-position="ageGroup.currencyPosition" />
                  </div>
                </div>
              </div>
              <div class="bp-num-cl-465 grid pb-2">
                <div class="bp-num-cl-466 w-6 font-bold">
                  {{ flightBookData.content.pageLabels.sidebar.totalPrice }}
                </div>
                <div class="bp-num-cl-467 w-6 font-bold text-right">
                  <FormattedCurrency :amount="selectedOfferData.sidebar.priceInfo.totalPrice.amount"
                                     :currency="selectedOfferData.sidebar.priceInfo.travelers[0].currency"
                                     :currency-position="selectedOfferData.sidebar.priceInfo.travelers[0].currencyPosition" />
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
  <Footer />
  <!-- EXPIRED DIALOG -->
  <Dialog :closeOnEscape="false"
          :header="flightBookData.content.pageLabels.timer.dialog.headline"
          v-model:visible="displayExpiredFlightModal"
          class="bp-num-cl-468 expired-modal shadow-5"
          :modal="true">
    <p class="bp-num-cl-469 m-0">
      {{ flightBookData.content.pageLabels.timer.dialog.subtitle }}
    </p>
    <template #footer
              class="bp-num-cl-470 pb-1">
      <div class="bp-num-cl-471 flex flex-wrap justify-content-center">
      </div>
      <div class="bp-num-cl-472 col-12 text-center">
        <Button @click="retrySearch"
                autofocus>
          <div class="bp-num-cl-473 text-white px-3">
            {{ flightBookData.content.pageLabels.timer.dialog.buttons.yes }}
          </div>
        </Button>
      </div>
      <div class="bp-num-cl-474 col-12 text-center">
        <Button @click="goHome"
                class="bp-num-cl-475 p-button-text py-0">
          <div class="bp-num-cl-476 px-2 py-0">
            {{ flightBookData.content.pageLabels.timer.dialog.buttons.no }}
          </div>
        </Button>
      </div>
    </template>
  </Dialog>

  <!-- PRICE CHANGE DIALOG -->
  <Dialog :header="flightBookData.content.pageLabels.priceChanged.dialog.headline[flightBookData.content.offers[0].priceChange.trend]"
          v-model:visible="priceChangeDialogOpened"
          class="bp-num-cl-477 expired-modal shadow-5"
          :modal="true">
    <div>
      <div class="bp-num-cl-478 m-0 pb-2 text-center flex w-12 justify-content-center">
        {{ flightBookData.content.pageLabels.priceChanged.dialog.old }}
        <div class="bp-num-cl-479 font-semibold">
          {{ ' ' }}
          <FormattedCurrency :currency="flightBookData.content.offers[0].priceChange.price.currency"
                             :amount="`${flightBookData.content.offers[0].priceChange.price.old}`"
                             :currencyPosition="flightBookData.content.offers[0].priceChange.price.currencyPosition" />
        </div>
      </div>
      <div class="bp-num-cl-480 pt-2 -mb-3 text-center flex w-12 justify-content-center">
        {{ flightBookData.content.pageLabels.priceChanged.dialog.new }}
        <div class="bp-num-cl-481 font-semibold">
          {{ ' ' }}
          <FormattedCurrency :currency="flightBookData.content.offers[0].priceChange.price.currency"
                             :amount="`${flightBookData.content.offers[0].priceChange.price.new}`"
                             :currencyPosition="flightBookData.content.offers[0].priceChange.price.currencyPosition" />
          {{ ' ' }}
        </div>
        <div class="bp-num-cl-482 triangle"
             :class="{
                up: flightBookData.content.offers[0].priceChange.trend != 'cheaper',
                down: flightBookData.content.offers[0].priceChange.trend == 'cheaper' 
        }">
          {{ '▲' }}
        </div>
      </div>
    </div>
    <template #footer
              class="bp-num-cl-483 pb-1">
      <div class="bp-num-cl-484 flex flex-wrap justify-content-center">
      </div>
      <div class="bp-num-cl-485 col-12 text-center">
        <Button @click="() => { priceChangeDialogOpened = false; }"
                autofocus>
          <div class="bp-num-cl-486 text-white px-3">
            {{ flightBookData.content.pageLabels.priceChanged.buttons.yes }}
          </div>
        </Button>
      </div>
      <div class="bp-num-cl-487 col-12 text-center">
        <Button @click="retrySearch"
                class="bp-num-cl-488 p-button-text py-0">
          <div class="bp-num-cl-489 px-2 py-0">
            {{ flightBookData.content.pageLabels.priceChanged.buttons.no }}
          </div>
        </Button>
      </div>
    </template>
  </Dialog>

</section>