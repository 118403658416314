import { computed, onBeforeMount, onBeforeUnmount, onMounted, onUpdated, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import FlightTime from '../../components/FlightTime/FlightTimeComponent.vue';
import StopDrawing from '../../components/StopDrawing/StopDrawingComponent.vue';
import MobileStopDrawing from '../../components/MobileStopDrawing/MobileStopDrawingComponent.vue';
import { useToast } from 'primevue/usetoast';
import detailUtils from '../../utils/detailUtils';
import store from '@/store';
import axios from 'axios';
import { expiryTimeForMessage } from '@/utils/watcherNew';

export default {
  name: 'FlightResults',
  components: { FlightTime, StopDrawing, MobileStopDrawing },
  setup: () => {
    const { getAirport, getPriceClass, getAirlineLogo, howManyCols, chunkifyPerks, getFilters, filterFlightResultsFlights } = detailUtils;

    const displayExpiredFlightModal = computed({
      get: () => {
        // console.log('get displayExpiredFlightModal whose value is: ', vuexStore.getters.displayExpiredFlightModal);
        return vuexStore.getters.displayExpiredFlightModal;
      },
      set: (newValue) => { vuexStore.dispatch('setDisplayExpiredFlightModal', newValue); }
    });

    const vuexStore = useStore();
    const vueRouter = useRouter();
    const toast = useToast(); toast.removeGroup('headless');
    const expiryToastAdded = ref(false);
    // toast.removeGroup('expiry');
    expiryToastAdded.value = false;

    store.dispatch('setAllFlightSearchImagesLoaded', true);
    const allFlightSearchImagesLoaded = computed(() => vuexStore.getters.allFlightSearchImagesLoaded);

    const expiryTime = ref(9999);
    const lastExpiryTimeUpdate = ref(Date.now().valueOf() - 1000);
    const filters = ref({});
    const flightTabData = ref({});
    const totalItemsCount = ref(0);
    const offset = ref(0);
    const limit = ref(10);
    const selectedStops = ref([]);
    const selectedAirlines = ref([]);
    const selectedTimes = ref([]);
    const selectedBaggage = ref([]);
    const flightDetails = ref({});
    const isFiltersSidebarOpen = ref(false);
    const ftv = ref({});
    const fieldsToValidate = ref(vuexStore.getters.fieldsToValidate);
    const resultsSessionResolved = ref(false);

    const baggage = computed(() => vuexStore.getters.flightResults.baggage);
    const loading = computed(() => vuexStore.getters.loading);
    const language = computed(() => vuexStore.getters.language);
    const pageLabels = computed(() => vuexStore.getters.flightResults.pageLabels);
    const flightResults = computed(() => vuexStore.getters.flightResults);
    const isMobileFlightSearchOpen = computed(() => vuexStore.getters.isMobileFlightSearchOpen);

    const travelerLabel = computed(() => totalTravelers.value > 1 ? pageLabels.value.travelers : pageLabels.value.traveler);

    const arrow = computed(() => {
      return 'https://putnik-backend.ibe-servers.com/assets/images/general/arrow' + (Array.isArray(vuexStore.getters.fieldsToValidate.dates) ? 's' : '') + '.svg';
    });

    const isDetailsSidebarOpen = computed({
      get: () => { return vuexStore.getters.isDetailsSidebarOpen; },
      set: (newValue) => { vuexStore.dispatch('setDetailsSidebarOpen', newValue); }
    });
    const isDetailsModalOpen = computed({
      get: () => { return vuexStore.getters.isDetailsModalOpen; },
      set: (newValue) => { vuexStore.dispatch('setIsDetailsModalOpen', newValue); }
    });

    const stopComputingSlicedFlights = ref(false);

    const populateFlightDetails = (flight) => {
      flightDetails.value = flight;
      const ffos = flight.flight?.outbound?.segments;
      flightDetails.value.oda = computed(() => { return { ...getAirport(ffos[0].departure.code), time: ffos[0].departure.time, date: ffos[0].departure.date }; });
      flightDetails.value.oaa = computed(() => { return { ...getAirport(ffos.at(-1).arrival.code), time: ffos.at(-1).arrival.time, date: ffos.at(-1).arrival.date }; });
      if ('inbound' in flight.flight) {
        const ffis = flight.flight?.inbound?.segments;
        flightDetails.value.ida = computed(() => { return { ...getAirport(ffis[0].departure.code), time: ffis[0].departure.time, date: ffis[0].departure.date }; });
        flightDetails.value.iaa = computed(() => { return { ...getAirport(ffis.at(-1).arrival.code), time: ffis.at(-1).arrival.time, date: ffis.at(-1).arrival.date }; });
      }
    };

    const populateFlightDetailsForSlice = (flight) => {
      const ffos = flight.flight?.outbound?.segments;
      flight.oda = computed(() => { return { ...getAirport(ffos[0].departure.code), time: ffos[0].departure.time, date: ffos[0].departure.date }; });
      flight.oaa = computed(() => { return { ...getAirport(ffos.at(-1).arrival.code), time: ffos.at(-1).arrival.time, date: ffos.at(-1).arrival.date }; });
      if ('inbound' in flight.flight) {
        const ffis = flight.flight?.inbound?.segments;
        flight.ida = computed(() => { return { ...getAirport(ffis[0].departure.code), time: ffis[0].departure.time, date: ffis[0].departure.date }; });
        flight.iaa = computed(() => { return { ...getAirport(ffis.at(-1).arrival.code), time: ffis.at(-1).arrival.time, date: ffis.at(-1).arrival.date }; });
      }
    };

    const slicedFlights = computed(() => {
      const flightResultFlights = filterFlightResultsFlights(flightResults, selectedAirlines, selectedTimes, selectedStops, selectedBaggage);

      const sliced = flightResultFlights.slice(offset.value, offset.value + limit.value);
      sliced.forEach(flight => {
        flight.expanded = ref(false);
        try {
          if (stopComputingSlicedFlights.value) {
            return;
          } else {
            flight = populateFlightDetailsForSlice(flight);
          }
        } catch (error) {
          console.error(error);
        }
      });
      totalItemsCount.value = flightResultFlights.length;
      return sliced;
    });

    const getBaggageFilter = (baggage) => {
      try {
        const outboundBaggage = {};
        baggage.outbound.list.forEach((b, i) => { outboundBaggage[i] = b; });
        baggage.outbound.list = outboundBaggage;
        if ('inbound' in baggage) {
          const inboundBaggage = {};
          baggage.inbound.list.forEach((b, i) => { inboundBaggage[i] = b; });
          baggage.inbound.list = inboundBaggage;
        }
        return getFilters(baggage);
      } catch (error) {
        return [];
      }
    };

    const groupedAirlines = computed(() => getFilters(flightResults.value.filters.airlines));
    const groupedTimes = computed(() => getFilters(flightResults.value.filters.times));
    const groupedStops = computed(() => getFilters(flightResults.value.filters.stops));
    const groupedBaggage = computed(() => getBaggageFilter(flightResults.value.filters.baggage));

    const totalTravelers = computed(() => {
      return vuexStore.getters.passengerCountObject.ADT + vuexStore.getters.passengerCountObject.CHD + vuexStore.getters.passengerCountObject.INF;
    });

    const comparePriceClasses = computed(() => {
      try {
        const a = getPriceClass(flightDetails.value.priceClasses.outbound?.priceCode);
        const b = getPriceClass(flightDetails.value.priceClasses.inbound?.priceCode);
        return JSON.stringify(a) === JSON.stringify(b);
      } catch (error) {
        console.error(error);
        return false;
      }
    });

    const toggleMobileFlightSearch = () => {
      vuexStore.dispatch('setIsMobileFlightSearchOpen', !isMobileFlightSearchOpen.value);
    };

    const reserve = ($event, flight) => {
      closeDetailsDialog();
      vuexStore.dispatch('setLoading', true);
      vuexStore.dispatch('setLoader', vuexStore.getters.flightResults.loader);
      populateFlightDetails(flight); console.log('triggered 2');
      vuexStore.dispatch('setFlightDetails', flightDetails.value);
      vuexStore.dispatch('setPageLabels', pageLabels.value);

      const url = `https://${vuexStore.getters.mainData.config.baseUrl}/${vuexStore.getters.language}/flight/book`;
      const data = {
        flightId: flightDetails.value.id,
        session: vuexStore.getters.session.value
      };

      clearInterval(sessionCheckIntervalId);

      axios.post(url, data)
        .then(async (response) => {
          const res = response.data;

          if (res.status === 'failure' && res.data?.error) {
            console.error(res.data.error);
            vuexStore.dispatch('setLoading', false);
            if (res.data.error.redirectUrl) {
              vueRouter.push(res.data.error.redirectUrl);
            }
            setTimeout(() => {
              sessionCheckIntervalId = resetSessionCheckInterval();
              toast.add({ group: 'headless', severity: 'warn', summary: '', detail: res.data.error.showMessage, life: 30000 });
            }, 200);
            return;
          }

          if ('loader' in res.data || (res.data.content && 'loader' in res.data.content)) {
            vuexStore.dispatch(
              'setLoader',
              res.data.loader || (res.data.content && res.data.content.loader)
            );
          }

          const json = await axios
            .get(`${res.data.config.apiUrl}/${res.data.config.bookParamsEncoded}`)
            .then((res) => res.data);

          if (json.data.content && 'error' in json.data.content) {
            if (json.data.error.redirectUrl) {
              vueRouter.push(json.data.error.redirectUrl);
            }
            setTimeout(() => {
              sessionCheckIntervalId = resetSessionCheckInterval();
              toast.add({ group: 'headless', severity: 'warn', summary: '', detail: json.data.error.showMessage, life: 30000 });
              vuexStore.dispatch('setLoading', false);
            }, 200);
            return;
          }

          vuexStore.dispatch('setFlightBookData', json.data);
          window.localStorage.setItem('session', json.data.config.session.value);
          window.localStorage.setItem(
            'bookParamsEncoded',
            json.data.config.paymentParamsEncoded || json.data.config.bookParamsEncoded
          );

          setTimeout(() => {
            vuexStore.dispatch('setLoading', true);
            vuexStore.dispatch('setShouldGetLoaderDataOnMounted', false);
          }, 1000);

          setTimeout(() => {
            vueRouter.push(`/${vuexStore.getters.language}/flight/book`);
          }, 2000);
        })
        .catch((error) => {
          console.error(error);
          toast.add({ group: 'headless', severity: 'warn', summary: '', detail: 'An error occurred during the request', life: 30000 });
          store.dispatch('setLoading', false);
        });
    };

    const openDetailsDialog = () => {
      ('opening');
      isDetailsModalOpen.value = true;
    };
    const closeDetailsDialog = () => {
      isDetailsModalOpen.value = false;
    };

    const openFiltersSidebar = () => {
      isFiltersSidebarOpen.value = true;
    };
    const closeFiltersSidebar = () => {
      isFiltersSidebarOpen.value = false;
    };
    const refreshPage = () => {
      location.reload();
    };
    const goHome = () => {
      toast.removeAllGroups();
      vueRouter.push(`/${vuexStore.getters.language}`);
    };

    const m = () => {
      vuexStore.dispatch('setLoading', !vuexStore.getters.loading);
    };

    const logChange = (event) => {
    };

    const changePage = (event) => {
    };

    const jan01ify = (dateString) => {
      const date = new Date(Date.parse(dateString));
      return `${date.toLocaleString(vuexStore.getters.locale, { day: '2-digit' })} ${date.toLocaleDateString(vuexStore.getters.locale, { month: 'long' }).slice(0, 3)}`;
    };

    const resetOffset = (event) => {
      offset.value = 0;
    };

    const openDetailsSidebar = () => {
      isDetailsSidebarOpen.value = true;
      store.dispatch('setIsDetailsSidebarOpen', true);
    };

    const closeDetailsSidebar = () => {
      isDetailsSidebarOpen.value = false;
      store.dispatch('setIsDetailsSidebarOpen', false);
    };

    const toggleDetailsSidebarOrModal = (event, flight) => {
      stopComputingSlicedFlights.value = true;
      setTimeout(() => {
        populateFlightDetails(flight); console.log('triggered 3');
        stopComputingSlicedFlights.value = false;

        if (window.innerWidth > 1200) {
          openDetailsDialog();
        } else {
          openDetailsSidebar();
        }
      }, 200);
    };

    onBeforeMount(() => {
      displayExpiredFlightModal.value = false;
    });

    onMounted(() => {
      resetOffset(undefined);
      displayExpiredFlightModal.value = false;

      expiryToastAdded.value = false;
      vuexStore.dispatch('setLoading', true);
      setTimeout(() => { getFlights(vuexStore, vueRouter).then(r => resolveFlights(r)); }, 1000);
    });

    onUpdated(() => {
      try {
        if (vuexStore.getters.loading !== true) {
          setTimeout(() => {
            let flightTimeElements;
            let flightNumberElements = [];
            let mobileStopDrawingElements = [];
            if (isDetailsModalOpen.value) {
              flightTimeElements = Array.from(document.querySelectorAll('.p-dialog-content .flight-time'));
              flightNumberElements = Array.from(document.querySelectorAll('.p-dialog-content .flight-number .flight-code'));
            } else if (isDetailsSidebarOpen.value) {
              flightTimeElements = Array.from(document.querySelectorAll('.p-sidebar-content .flight-time'));
              mobileStopDrawingElements = Array.from(document.querySelectorAll('.mobile-stop-drawing'));
              flightNumberElements = Array.from(document.querySelectorAll('.flight-number .flight-code'));
            } else {
              flightTimeElements = Array.from(document.querySelectorAll('.single-flight .flight-time'));
            }
            const b = flightTimeElements.map(item => item.offsetWidth);
            const c = Math.max.apply(null, b);
            flightTimeElements.forEach(element => {
              element.style.cssText = `width: ${c}px`;
            });
            const d = flightNumberElements.map(item => item.offsetWidth);
            const e = Math.max.apply(null, d);
            flightNumberElements.forEach(element => {
              element.style.cssText = `width: ${e}px; text-align: right; white-space: nowrap;`;
            });
            const screenWidth = window.innerWidth;
            const mobileStopDrawingWidth = screenWidth - 40 - 2 * c;
            mobileStopDrawingElements.forEach(element => {
              element.style.cssText = `width: ${mobileStopDrawingWidth}px`;
            });
          }, 50);
        }
      } catch (error) {
        //
      }
    });

    if (!store.getters.isExpiryBeingWatched) {
      //  watchExpire(expiryTime, lastExpiryTimeUpdate, expiryToastAdded, toast, flightResults.value.pageLabels.timer.headline, displayExpiredFlightModal);
      store.dispatch('setIsExpiryBeingWatched', true);
    }

    watch(() => vuexStore.getters.searchSessionlessParamsEncoded, (newValue, oldValue) => {
      if (oldValue !== '') {
        expiryToastAdded.value = false;
        getFlights(vuexStore, vueRouter)
          .then(r => resolveFlights(r));
      }
    }, { deep: true });

    watch(() => fieldsToValidate, (nv_) => {
      vuexStore.dispatch('setFieldsToValidate', nv_);
      fieldsToValidate.value = vuexStore.getters.fieldsToValidate;
    }, { deep: true });

    watch(() => vuexStore.getters.language, async (newValue) => {
      await getFlights(vuexStore, vueRouter);
      resolveFlights();
    }, { deep: true });

    watch(() => vuexStore.getters.newSearches, (newValue) => {
      resetFiltersAndPagination();
    }, { deep: true });

    let sessionCheckIntervalId = null;
    sessionCheckIntervalId = setInterval(() => {
      // console.log('here');
      if (`${vuexStore.getters.session.value}` === 'undefined' || `${vuexStore.getters.session.value}` === '') {
        return;
      }
      axios.get(`https://${vuexStore.getters.mainData.config.baseUrl}/${store.getters.language}/flight/get-session/${vuexStore.getters.session.value}`).then(response => {
        // console.log('response.data: ', response.data.data.ttl);
        expiryTime.value = response.data.data.ttl;

        if (expiryTime.value > 300) {
          toast.removeGroup('expiry');
          expiryToastAdded.value = false;
          // console.log('Expiry still greater than 300 seconds, not adding toast');
        }

        const now = new Date();
        const expiryDate = new Date(Date.now() + expiryTime.value * 1000);
        const remaining = (expiryDate - now) / 1000;

        store.dispatch('setExpiryTimeForMessage', expiryTimeForMessage(Math.floor(remaining)));

        if (!expiryToastAdded.value && expiryTime.value < response.data.data.timerWarningAt) {
          // console.log('lets goooooo');
          toast.removeGroup('expiry');
          setTimeout(() => {
            toast.add({
              severity: 'info',
              group: 'expiry',
              summary: flightResults.value.pageLabels.timer.headline,
              detail: response.data.data.label,
              life: 5000
            });
          }, 10);
        } else {
          // console.log('Expiry toast already added or remaining time is greater than 300 seconds, not adding toast, these vars are ', expiryToastAdded.value, expiryTime.value);
        }
        if (remaining <= 1) {
          clearInterval(sessionCheckIntervalId);
          toast.removeAllGroups();
          // console.log('Session watcher expired');
          displayExpiredFlightModal.value = true;
        } else {
          displayExpiredFlightModal.value = false;
        }
      }).catch(error => {
        console.error(error);
      });
    }, 60000);

    store.dispatch('setComingFromSearchToBook', true);

    onBeforeUnmount(() => {
      clearInterval(sessionCheckIntervalId);
      toast.removeGroup('expiry');
    });

    const stopSessionCheck = (shouldStop) => {
      if (shouldStop) {
        // console.log('stopping session check');
        clearInterval(sessionCheckIntervalId);
        toast.removeGroup('expiry');
      }
    };

    const pageLinkSize = ref(10);
    // lower to 4 when mobile width, return to 10 when desktop width
    onMounted(() => {
      watch(() => window.innerWidth, (newValue) => {
        // console.log('window.innerWidth', newValue);
        pageLinkSize.value = newValue < 1200 ? 4 : 10;
      });
    });

    return {
      pageLinkSize,
      stopSessionCheck,
      allFlightSearchImagesLoaded,
      groupedBaggage,
      selectedBaggage,
      comparePriceClasses,
      reserve,
      refreshPage,
      goHome,
      displayExpiredFlightModal,
      arrow,
      howManyCols,
      chunkifyPerks,
      expiryTime,
      toggleMobileFlightSearch,
      isMobileFlightSearchOpen,
      totalTravelers,
      m,
      closeFiltersSidebar,
      openFiltersSidebar,
      isFiltersSidebarOpen,
      baggage,
      travelerLabel,
      fieldsToValidate,
      flightDetails,
      flightResults,
      flightTabData,
      groupedAirlines,
      groupedStops,
      groupedTimes,
      isDetailsModalOpen,
      isDetailsSidebarOpen,
      language,
      limit,
      loading,
      offset,
      pageLabels,
      selectedAirlines,
      selectedStops,
      selectedTimes,
      showSidebarCloseIcon: true,
      slicedFlights,
      totalItemsCount,
      resultsSessionResolved,
      changePage,
      getAirlineLogo,
      getAirport,
      getPriceClass,
      jan01ify,
      logChange,
      openDetailsDialog,
      resetOffset,
      toggleDetailsSidebarOrModal,
      closeDetailsSidebar
    };

    function resetSessionCheckInterval() {
      // console.log('resetting session check interval');
      const setIntervalFunction = () => {
        // console.log('here');
        if (`${vuexStore.getters.session.value}` === 'undefined' || `${vuexStore.getters.session.value}` === '') {
          return;
        }
        axios.get(`https://${vuexStore.getters.mainData.config.baseUrl}/${store.getters.language}/flight/get-session/${vuexStore.getters.session.value}`).then(response => {
          // console.log('response.data: ', response.data.data.ttl);
          expiryTime.value = response.data.data.ttl;

          if (expiryTime.value > 300) {
            toast.removeGroup('expiry');
            expiryToastAdded.value = false;
            // console.log('Expiry still greater than 300 seconds, not adding toast');
          }

          const now = new Date();
          const expiryDate = new Date(Date.now() + expiryTime.value * 60000);
          const remaining = (expiryDate - now) / 1000;

          store.dispatch('setExpiryTimeForMessage', expiryTimeForMessage(Math.floor(remaining)));

          if (expiryTime.value < response.data.data.timerWarningAt) {
            // console.log('lets goooooo');
            toast.removeGroup('expiry');
            setTimeout(() => {
              toast.add({
                severity: 'info',
                group: 'expiry',
                summary: flightResults.value.pageLabels.timer.headline,
                detail: response.data.data.label,
                life: 5000
              });
            }, 10);
          } else {
            // console.log('Expiry toast already added or remaining time is greater than 300 seconds, not adding toast, these vars are ', expiryToastAdded.value, expiryTime.value);
          }
          if (remaining <= 1) {
            clearInterval(sessionCheckIntervalId);
            toast.removeAllGroups();
            // console.log('Session watcher expired');
            displayExpiredFlightModal.value = true;
          } else {
            displayExpiredFlightModal.value = false;
          }
        }).catch(error => {
          console.error(error);
        });
      };

      const sessionCheckIntervalId = setInterval(setIntervalFunction, 60000);

      return sessionCheckIntervalId;
    }

    function resetFiltersAndPagination() {
      selectedAirlines.value = [];
      selectedStops.value = [];
      selectedTimes.value = [];
      offset.value = 0;
    }

    function resolveFlights(f) {
      resetFiltersAndPagination();
      Promise.resolve(f).then(response => {
        if (!response) {
          setTimeout(() => {
            store.dispatch('setLoading', false);
          }, 1000);
          return;
        }
        if (response?.content?.error) {
          console.error(response.content.error);
          if (response.content.error.redirectUrl) {
            vueRouter.push(response.content.error.redirectUrl);
          }
          setTimeout(() => {
            vuexStore.dispatch('setLoading', false);
            if (response.content.error.type === 'toast') {
              toast.add({ group: 'headless', severity: 'warn', summary: '', detail: response.content.error.showMessage, life: 30000 });
            }
          }, 200);
        } else {
          if (response?.config?.session) {
            vuexStore.dispatch('setSession', response.config.session);
          }
          const rcsp = response.config.searchParams;
          ftv.value = rcsp;
          vuexStore.dispatch('setDepartureCache', new Date(rcsp.departureDate));
          if ('returnDate' in rcsp && rcsp.returnDate !== '') {
            vuexStore.dispatch('setOneWayFromStore', false);
            vuexStore.dispatch('setReturnCache', new Date(rcsp.returnDate));
            fieldsToValidate.value.dates = [new Date(ftv.value.departureDate), new Date(ftv.value.returnDate)];
          } else {
            fieldsToValidate.value.dates = new Date(ftv.value.departureDate);
            vuexStore.dispatch('setOneWayFromStore', true);
            vuexStore.dispatch('setReturnCache', null);
          }
          expiryTime.value = response.config.session.ttl;
          store.dispatch('setExpiry', Date.now().valueOf() + response.config.session.ttl * 1000);
          filters.value = response.content.filters;
          flightTabData.value = response.content.form;
          fieldsToValidate.value.departure = ftv.value.departure;
          fieldsToValidate.value.arrival = ftv.value.arrival;
          vuexStore.dispatch('setFlightResults', response.content);
          vuexStore.dispatch('setFlightResultsHeader', response.header);
          const passengerObject = {
            ADT: rcsp.travelers.ADT ? Number.parseInt(rcsp.travelers.ADT) : 1,
            CHD: rcsp.travelers.CHD ? Number.parseInt(rcsp.travelers.CHD) : 0,
            INF: rcsp.travelers.INF ? Number.parseInt(rcsp.travelers.INF) : 0
          };
          vuexStore.dispatch('setPassengerCountObject', passengerObject);
          // vueRouter.push('/' + window.location.href.split('/')[3] + `${response.config.url}${response.config.searchSessionlessParamsEncoded}`.slice(3));
          vueRouter.push(`${response.config.url}${response.config.searchSessionlessParamsEncoded}`);

          setTimeout(() => {
            vuexStore.dispatch('setLoading', false);
          }, 200);
        }
      }).catch(err => {
        console.error(err);
        vueRouter.push(`/${vuexStore.getters.language}`);
      }).finally(() => {
        resultsSessionResolved.value = true;
      });
    }

    async function getFlights() {
      try {
        if (vuexStore.getters.searchSessionlessParamsEncoded === '') {
          vuexStore.dispatch('setSearchSessionlessParamsEncoded', vueRouter.currentRoute.value.params.searchSessionlessParamsEncoded);
        }
        const baseUrl_ = vuexStore.getters.mainData.config.baseUrl;
        const lang_ = vueRouter.currentRoute.value.params.language;
        const spe_ = vuexStore.getters.searchSessionlessParamsEncoded;
        const session = window.localStorage.getItem('session');
        const a = await fetch(`https://${baseUrl_}/${lang_}/flight/search/${spe_}${session ? `/${session}` : ''}`);
        const b = await a.json();

        if (b.data?.content?.error) {
          console.error(b.data?.content?.error);
          if (b.data?.content?.error.redirectUrl) {
            vueRouter.push(b.data?.content?.error.redirectUrl);
            if (b.data?.content?.error.type === 'toast') {
              toast.add({ group: 'headless', severity: 'warn', summary: '', detail: b.data.content.error.showMessage, life: 30000 });
            }
          }
          return;
        }

        if (b.data?.error) {
          console.error(b.data?.error);
          if (b.data?.error.showMessage?.redirectUrl) {
            vueRouter.push(b.data?.error.showMessage.redirectUrl);
            if (b.data?.error.type === 'toast') {
              toast.add({ group: 'headless', severity: 'warn', summary: '', detail: b.data.error.showMessage, life: 30000 });
            }
          }
          return;
        }

        window.localStorage.setItem('session', b.data.config.session.value);

        if ('loader' in b.data || (b.data.content && 'loader' in b.data.content)) {
          setTimeout(() => {
            store.dispatch('setLoader', b.data.loader || (b.data.content && 'loader' in b.data.content && b.data.content.loader));
          }, 5000);
        }
        return b.data;
      } catch (err) {
        console.error(err);
        return {
        };
      }
    }
  }
};
