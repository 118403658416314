import { computed, nextTick, onBeforeUnmount, onUpdated, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import detailUtils from '@/utils/detailUtils';
import FlightTime from '../../components/FlightTime/FlightTimeComponent.vue';
import MyInputText from '../../components/MyInputText.vue';
import MobileStopDrawing from '../../components/MobileStopDrawing/MobileStopDrawingComponent.vue';
import { luhnValidate } from '@/utils/luhnUtils';
import { useToast } from 'primevue/usetoast';
import { expiryTimeForMessage } from '@/utils/watcherNew';
import axios from 'axios';
// import SecureFields from '../../datatrans/datatrans';

export default {
  name: 'payment-page',
  components: {
    FlightTime,
    MobileStopDrawing,
    MyInputText
  },
  props: [],
  setup() {
    /* eslint-disable */
    const styles = {
      // Style Applying to all Elements
      "*": "border: 2px solid black; background- color: blue; padding: .65em .5em",

      // JSON:
      //'*': {
      //    border: '2px solid black',
      //    padding: '.65em .5em'
      //    backgroundColor: 'blue',  // background-color
      //    backgroundImage: 'none',  // background-image
      //    boxSizing: 'border-box',  // box-sizing
      //    WebkitBoxShadow: 'none',  // -webkit-box-shadow
      //    WebkitAppearance: 'none'  // -webkit-appearance
      //}

      // ::hover Applying to all Elements
      "*::hover": "-webkit-box-shadow: none; - ms - box - shadow: none; -moz - appearance: none; ",

      // Specific Style for Each Element
      cardNumber: "-webkit-text-size-adjust: 100%; line-height: 1.15; width: 100% !important; font-weight: normal; font-family: Poppins, -apple-system, BlinkMacSystemFont, \"Segoe UI\"; font-size: 0.875rem; color: #383640; background: #ffffff; padding: 0.5rem 0.5rem; border: 1px solid #ced4da; appearance: none; border-radius: 5px; box-sizing: border-box; margin: 0; border-color: #ced4da; height:34px; ",
      cvv: "-webkit-text-size-adjust: 100%; line-height: 1.15; width: 100% !important; font-weight: normal; font-family: Poppins, -apple-system, BlinkMacSystemFont, \"Segoe UI\"; font-size: 0.875rem; color: #383640; background: #ffffff; padding: 0.5rem 0.5rem; border: 1px solid #ced4da; appearance: none; border-radius: 5px; box-sizing: border-box; margin: 0; border-color: #ced4da; height:34px; ",

      // Toggled CSS Classes
      "cardNumber:hover": "border-color: #2196f3;",
      // "cardNumber.valid:hover": "background-color: green;",
      // "cardNumber.invalid:hover": "background-color: red;",

      // Further Selectors
      '*:focus': 'border: 1px solid #66AFE9',
      '*:hover': 'border: 1px solid #66AFE9',
      '*::placeholder': 'color: #999999',
      '*:-ms-input-placeholder': 'color: #999999',

      // Custom Font
      "*": "font-family: Metamorphous;",
      "@font-face": {
        "*": {
          fontFamily: "Metamorphous",
          fontStyle: "normal",
          fontWeight: 400,
          src: "url('metamorphous.woff2') format('woff2')"
        }
      }
    };
    /* eslint-enable */

    const SecureFields = require('../../datatrans/datatrans.js');
    const secureFields = new SecureFields({
      styles: styles
      // style: {
      //   base: {
      //     color: '#000',
      //     fontSize: '16px',
      //     fontFamily: 'Arial, sans-serif',
      //     lineHeight: '1.5',
      //     backgroundColor: '#fff',
      //     border: '1px solid #ccc',
      //     borderRadius: '4px',
      //     padding: '10px',
      //     width: '100%',
      //     boxSizing: 'border-box'
      //   },
      //   focus: {
      //     borderColor: '#f00'
      //   },
      //   invalid: {
      //     borderColor: '#ff0000'
      //   }
      // }
    });

    const submitSecureFields = () => {
      // console.log('submitSecureFields');
      // console.log('secureFields', secureFields);
      // log the submit function
      // console.log('secureFields.submit', secureFields.submit);
      try {
        secureFields.submit({
          expm: 6,
          expy: 25
        });
      } catch (error) {
        console.error('Error submitting secure fields:', error);
      }
      // secureFields.submit({
      // expm: 6,
      // expy: 25
      // });
    };

    let sessionCheckIntervalId = null;

    const toast = useToast(); toast.removeGroup('headless');
    const expiryToastAdded = ref(false);
    // toast.removeGroup('expiry');
    expiryToastAdded.value = false;

    const tabViewActiveIndex = ref(0);
    const paymentMethodActiveIndex = ref(0);

    const { getAirport, getPriceClass, getAirlineLogo, howManyCols, chunkifyPerks } = detailUtils;
    const router = useRouter();
    const store = useStore();
    const airplaneIcon = 'https://putnik-backend.ibe-servers.com/assets/images/general/airplane.svg';

    const priceChangeDialogOpened = ref(false);

    const sessionResolved = ref(false);
    const consentPassport = ref(false);
    const consentVisa = ref(false);
    const consentTc = ref(false);
    const checkInvalid = ref(false);
    const cc = ref({ cardHolder: '', number: '', expireDate: '', cvv: '' });
    const cardLength = ref(19);
    const expiryTime = ref(9999);
    const activeIndex = ref(null);
    const baggageAccordionActiveIndex = ref(null);

    const language = computed(() => store.getters.language);
    const paymentStoreData = computed(() => store.getters.paymentData);
    const device = computed(() => store.getters.device);
    const breadcrumbs = computed(() => store.getters.paymentData.data.content.breadcrumbNavigation);
    const flightDetails = computed(() => store.getters.flightDetails);
    const cvvLength = computed(() => cc.value.number.charAt(0) === '3' ? 4 : 3);
    const isSidebarFixed = ref(false);
    const isSidebarDockedToBottom = ref(false);
    const displayExpiredFlightModal = computed({
      get: () => { return store.getters.displayExpiredFlightModal; },
      set: (newValue) => { store.dispatch('setDisplayExpiredFlightModal', newValue); }
    });

    if (!window.localStorage.getItem('session') || !window.localStorage.getItem('paymentParamsEncoded')) {
      // console.warn('no session or params, kicking to home pages');
      router.push(`/${language.value}`);
    } else {
      store.dispatch('setSession', window.localStorage.getItem('session'));
      store.dispatch('setPaymentParamsEncoded', window.localStorage.getItem('paymentParamsEncoded'));
      if (!store.getters.bookToPay) {
        store.dispatch('setBookToPay', false);
      }
      getPaymentDataOnRefresh();
    }

    window.addEventListener('scroll', handleSidebarPosition);

    const fixCvv = (_event) => {
      cc.value.cvv = cc.value.cvv.replace(/\D/g, '');
    };

    if (!store.getters.isExpiryBeingWatched) {
      //  watchExpire(expiryTime, lastExpiryTimeUpdate, expiryToastAdded, toast, paymentStoreData.value.data.content.pageLabels.timer.headline, displayExpiredFlightModal);
      store.dispatch('setIsExpiryBeingWatched', true);
    }

    setTimeout(() => {
      expiryTime.value = paymentStoreData.value.data.config.session.ttl;
      store.dispatch('setExpiry', Date.now().valueOf() + (paymentStoreData.value.data.config.session.ttl * 1000));
    }, 500);

    onUpdated(() => {
      if (store.getters.loading !== true) {
        nextTick().then(() => {
          try {
            let flightTimeElements = [];
            let flightNumberElements = [];

            if (activeIndex.value === 0) {
              flightTimeElements = Array.from(document.querySelectorAll('.p-accordion .flight-time'));
              flightNumberElements = Array.from(document.querySelectorAll('.p-accordion .flight-number .flight-code'));
            } else {
              flightTimeElements = Array.from(document.querySelectorAll('.p-accordion .single-flight .flight-time'));
            }

            const maxFlightTimeWidth = Math.max(...flightTimeElements.map(item => item.offsetWidth));
            flightTimeElements.forEach(element => {
              element.style.width = `${maxFlightTimeWidth}px`;
            });

            const maxFlightNumberWidth = Math.max(...flightNumberElements.map(item => item.offsetWidth));
            flightNumberElements.forEach(element => {
              element.style.cssText = `width: ${maxFlightNumberWidth}px; text-align: right; white-space: nowrap;`;
            });
          } catch (error) {
            console.error('Error updating flight elements:', error);
          }
        });
      }
    });

    const retrySearch = () => {
      window.location.href = window.location.origin + paymentStoreData.value.data.content.breadcrumbNavigation.items[0].url;
    };

    const declinePriceChange = () => {
      window.location.href = window.location.origin + paymentStoreData.value.data.content.breadcrumbNavigation.items[1].url;
    };

    const submitPayment = async () => {
      if (isDatatransCardValid.value && isDatatransCvvValid.value) {
        await clickMe();
      } else {
        await submitPaymentRoutine();
      }
    };

    const regexifyCardholder = (charset, field, _event) => {
      const regex = new RegExp(`[^${charset}]+`);
      cc.value[field] = cc.value[field].replace(regex, '');
    };

    const addSpacesToCc = (_event) => {
      cc.value.number = cc.value.number.replace(/\D/g, '');
      if (typeof _event !== 'boolean' && _event) {
        cc.value.expireDate = '';
        cc.value.cvv = '';
      }
      try {
        const isAmex = cc.value.number && cc.value.number.charAt(0) === '3';
        if (isAmex) {
          cardLength.value = 17;
          const pt1 = cc.value.number && cc.value.number.replaceAll(' ', '').slice(0, 4) + ' ';
          const pt2 = cc.value.number && cc.value.number.replaceAll(' ', '').slice(4, 10) + ' ';
          const pt3 = cc.value.number && cc.value.number.replaceAll(' ', '').slice(10, 17);
          cc.value.number = `${pt1}${pt2}${pt3}`.trimEnd();
        } else {
          cardLength.value = 19;
          const arr = cc.value.number.replaceAll(' ', '').match(/.{1,4}/g);
          cc.value.number = arr.join(' ');
        }
      } catch (error) {
        setTimeout(() => {
          Promise.resolve({});
        }, 1);
      }
    };

    const checkCcKey = (key) => {
      let x = false;
      const n = cc.value.number;
      if (key === 'VI') {
        x = n.length >= 4 && n.charAt(0) !== '4';
        return x;
      }
      if (key === 'MC') {
        x = n.length >= 4 && n.charAt(0) !== '2' && n.charAt(0) !== '5';
        return x;
      }
      if (key === 'AX') {
        x = n.length >= 4 && n.charAt(0) !== '3';
        return x;
      }
    };

    const isCcLuhnCompliant = () => {
      const rawCcString = cc.value.number.replaceAll(' ', '');
      const isCardNumberAtMaxLength = cc.value.number.length === cardLength.value;
      return luhnValidate(rawCcString) || !isCardNumberAtMaxLength;
    };

    const validateExpiryDate = (_event) => {
      if (_event.inputType !== 'deleteContentBackward') {
        cc.value.expireDate = cc.value.expireDate.replace(/\D/g, '');
        const pt1 = cc.value.expireDate.slice(0, 2);
        const pt2 = cc.value.expireDate.slice(2, 4);
        if (pt1.length === 2) {
          cc.value.expireDate = pt1 + '/' + pt2;
        }
      }
    };

    const checkAgainstRegex = (field, regex) => {
      const r = new RegExp(regex);
      const isMatch = field?.toString().match(r);
      return isMatch;
    };

    const fareRulesDialogVisible = ref(false);
    const toggleFareRulesDialog = () => {
      fareRulesDialogVisible.value = !fareRulesDialogVisible.value;
    };

    store.dispatch('setPaymentSession', window.localStorage.getItem('session'));
    sessionCheckIntervalId = setInterval(() => {
      setFinalInterval();
    }, 60000);

    store.dispatch('setComingFromSearchToBook', true);

    onBeforeUnmount(() => {
      clearInterval(sessionCheckIntervalId);
      toast.removeGroup('expiry');
    });

    const goHome = () => {
      toast.removeAllGroups();
      router.push(`/${store.getters.language}`);
    };

    const paymentTypeRef = ref('inv');

    const onTabChange = (e) => {
      paymentTypeRef.value = paymentStoreData.value.data.content.form.payment.types[e.index];
      // console.log('onTabChange', e.index);
    };

    const isDatatransCardValid = ref(false);
    const isDatatransCvvValid = ref(false);

    const datatransCardLength = ref(0);
    const datatransCvvLength = ref(0);

    const clickMe = async () => {
      store.dispatch('setLoading', true);
      const isPaymentDataOk = validatePayment();
      if (!isPaymentDataOk) {
        console.log('payment data is not ok.');
      }

      // Submit cardholder and exp to Webpro
      let cardholderResponse;
      try {
        cardholderResponse = await axios.post('https://putnik-backend.ibe-servers.com/de/flight/datatrans/transaction-cardholder', {
          cardHolder: cc.value.cardHolder,
          expireDate: cc.value.expireDate.replace(/\D/g, ''),
          transactionId: paymentStoreData.value.data.config.datatrans.transactionId,
          session: paymentStoreData.value.data.config.session.value
        });
      } catch (error) {
        console.error(error);
        if (error.response?.data?.data?.error?.type === 'toast') {
          console.log(error.response.data.data.error);
          toast.add({ group: 'headless', severity: 'warn', summary: '', detail: error.response.data.data.error.showMessage, life: 5000 });
        }
        store.dispatch('setLoading', false);
        return;
      }

      console.log(cardholderResponse.data);
      if (cardholderResponse.data.status !== 'success') {
        console.log('phail');
        return;
      } else {
        console.log('suxes');
      }

      secureFields.on('success', async (data) => {
        if (data.redirect) {
          console.log('redirecting to ', data.redirect);
          window.location.href = data.redirect;
        } else {
          await submitPaymentRoutine(true);
        }
      });

      secureFields.on('error', async (data) => {
        // this will always fail
        await submitPaymentRoutine(true);
        // toast.add({ group: 'headless', severity: 'warn', summary: ' ', detail: 'datatrans error ', life: 5000 });
        // store.dispatch('setLoading', false);
      });

      console.log('clickMe');
      const expm = cc.value.expireDate.charAt(0) === '0' ? cc.value.expireDate.slice(1, 2) : cc.value.expireDate.slice(0, 2);
      const expy = cc.value.expireDate.slice(3, 5);
      resetCardholderAndExp();
      secureFields.submit({ expm, expy });
    };

    const resetCardholderAndExp = () => {
      cc.value.cardHolder = '';
      cc.value.expireDate = '';
    };

    return {
      isDatatransCardValid,
      isDatatransCvvValid,
      datatransCardLength,
      datatransCvvLength,

      activeIndex,
      airplaneIcon,
      baggageAccordionActiveIndex,
      breadcrumbs,
      cardLength,
      cc,
      checkInvalid,
      consentPassport,
      consentTc,
      consentVisa,
      cvvLength,
      device,
      displayExpiredFlightModal,
      flightDetails,
      fareRulesDialogVisible,
      isSidebarDockedToBottom,
      isSidebarFixed,
      language,
      paymentMethodActiveIndex,
      paymentStoreData,
      priceChangeDialogOpened,
      router,
      sessionResolved,
      store,
      tabViewActiveIndex,
      addSpacesToCc,
      checkAgainstRegex,
      checkCcKey,
      checkCvvLength,
      chunkifyPerks,
      declinePriceChange,
      fixCvv,
      getAirlineLogo,
      getAirport,
      getPriceClass,
      goHome,
      howManyCols,
      isCcLuhnCompliant,
      isValidExpireDate,
      onTabChange,
      regexifyCardholder,
      retrySearch,
      submitPayment,
      toggleFareRulesDialog,
      validateExpiryDate,

      submitSecureFields
    };

    function setFinalInterval() {
      if (`${store.getters.paymentSession}` === 'undefined' || `${store.getters.paymentSession}` === '') {
        return;
      }
      axios.get(`https://${store.getters.mainData.config.baseUrl}/${store.getters.language}/flight/get-session/${store.getters.paymentSession}`).then(response => {
        // console.log('response.data: ', response.data.data.ttl);
        expiryTime.value = response.data.data.ttl;

        if (expiryTime.value > 300) {
          toast.removeGroup('expiry');
          expiryToastAdded.value = false;
          // console.log('Expiry still greater than 300 seconds, not adding toast');
        }

        const now = new Date();
        const expiryDate = new Date(Date.now() + expiryTime.value * 1000);
        const remaining = (expiryDate - now) / 1000;

        store.dispatch('setExpiryTimeForMessage', expiryTimeForMessage(Math.floor(remaining)));

        if (!expiryToastAdded.value && expiryTime.value < response.data.data.timerWarningAt) {
          // console.log('lets goooooo');
          toast.removeGroup('expiry');
          setTimeout(() => {
            toast.add({
              severity: 'info',
              group: 'expiry',
              summary: paymentStoreData.value.data.content.pageLabels.timer.headline,
              detail: response.data.data.label,
              life: 5000
            });
          }, 10);
        } else {
          // console.log('Expiry toast already added or remaining time is greater than 300 seconds, not adding toast, these vars are ', expiryToastAdded.value, expiryTime.value);
        }
        if (remaining <= 1) {
          clearInterval(sessionCheckIntervalId);
          toast.removeAllGroups();
          // console.log('Session watcher expired');
          displayExpiredFlightModal.value = true;
        } else {
          displayExpiredFlightModal.value = false;
        }
      }).catch(error => {
        console.error(error);
      });
    }

    function isValidExpireDate(a) {
      const san = a.replace(/\D/g, '');
      const arr = [];
      const minDate = paymentStoreData.value.data.content.form.payment.cc.fields.expireDate.minDate;
      const maxDate = paymentStoreData.value.data.content.form.payment.cc.fields.expireDate.maxDate;
      const minMonth = Number(minDate.slice(0, 2));
      const maxMonth = Number(maxDate.slice(0, 2));
      const minYear = Number(minDate.slice(2, 4));
      const maxYear = Number(maxDate.slice(2, 4));

      for (let i = minMonth; i <= 12; i++) {
        const date = i < 10 ? `0${i}${minYear}` : `${i}${minYear}`;
        arr.push(date);
      }
      for (let i = 1; i <= maxMonth; i++) {
        const date = i < 10 ? `0${i}${maxYear}` : `${i}${maxYear}`;
        arr.push(date);
      }
      for (let i = minYear + 1; i < maxYear; i++) {
        for (let j = 1; j <= 12; j++) {
          const date = j < 10 ? `0${j}${i}` : `${j}${i}`;
          arr.push(date);
        }
      }

      return arr.some(element => element === san);
    }

    function validatePayment() {
      // log the payment type ref
      // console.log('paymentTypeRef', paymentTypeRef.value);
      // return consentPassport.value && consentVisa.value && consentTc.value;

      checkInvalid.value = true;
      setTimeout(() => {
        checkInvalid.value = false;
      }, 2500);
      // return false;

      let cardInfoValidated = false;

      // if (paymentMethodActiveIndex.value === 0) {
      if (paymentTypeRef.value === 'cc') {
        cardInfoValidated = true;
        const ccFieldstore = paymentStoreData.value.data.content.form.payment.cc.fields;
        if (cc.value.cardHolder === '') {
          cardInfoValidated = false;
        }
        if (cc.value.cardHolder === '' || !checkAgainstRegex(cc.value.cardHolder, ccFieldstore.cardHolder.regex)) {
          cardInfoValidated = false;
        }
        // if (cc.value.number.length < cardLength.value) {
        //   cardInfoValidated = false;
        // }
        // if (cc.value.number === '' || !checkAgainstRegex(cc.value.number, ccFieldstore.number.regex)) {
        //   cardInfoValidated = false;
        // }
        if (!isDatatransCardValid.value) {
          cardInfoValidated = false;
        }
        if (!isValidExpireDate(cc.value.expireDate)) {
          cardInfoValidated = false;
        }
        // if (cc.value.cvv.length < cvvLength.value) {
        //   cardInfoValidated = false;
        // }
        if (!isDatatransCvvValid.value) {
          cardInfoValidated = false;
        }
      } else {
        cardInfoValidated = true;
        cc.value.cardHolder = '';
        cc.value.number = '';
        cc.value.expireDate = '';
        cc.value.cvv = '';
      }
      if (!cardInfoValidated) {
        setTimeout(() => {
          let firstInvalidElement = document.querySelector('.p-error').parentElement.parentElement.parentElement;
          if (paymentTypeRef.value === 'cc') {
            firstInvalidElement = document.querySelector('.buyer-data .p-error').parentElement.parentElement.parentElement;
          }
          firstInvalidElement.scrollIntoView({ behavior: 'smooth' });
        }, 50);
      }
      return cardInfoValidated && consentPassport.value && consentVisa.value && consentTc.value;
    }

    function reinitSecureFields(tid) {
      setTimeout(() => {
        secureFields.init(tid, {
          cardNumber: 'cardNumberPlaceholder',
          cvv: 'cvvPlaceholder'
        }, {
          styles: styles
        });

        isDatatransCardValid.value = false;
        isDatatransCvvValid.value = false;
        datatransCardLength.value = 0;
        datatransCvvLength.value = 0;
        // secureFields.setStyle("cardNumber", "border: 1px solid #ccc");
        // secureFields.setStyle("cvv", "border: 1px solid #ccc");
        secureFields.on('change', function (event) {
          isDatatransCardValid.value = event?.fields?.cardNumber?.valid;
          isDatatransCvvValid.value = event?.fields?.cvv?.valid;
          datatransCardLength.value = event?.fields?.cardNumber?.length;
          datatransCvvLength.value = event?.fields?.cvv?.length;
        });
      }, 2000);
    }

    async function getPaymentDataOnRefresh() {
      store.dispatch('setLoading', true);
      try {
        console.log('fetching payment data, params are ', store.getters.mainData.config.baseUrl, store.getters.language, store.getters.paymentParamsEncoded);
        const a = await fetch(`https://${store.getters.mainData.config.baseUrl}/${store.getters.language}/flight/payment/${store.getters.paymentParamsEncoded}`);
        const b = await a.json();

        if (b.data?.config?.datatrans?.authorized?.status) {
          console.log('already authorized, redirecting to PNR');
          const ccPaymentRoutineSuccessful = await submitPaymentRoutine(true);
          if (ccPaymentRoutineSuccessful) {
            return;
          }
        }

        if (b.data && 'error' in b.data) {
          if (b.data.error.redirectUrl) {
            router.push(b.data.error.redirectUrl);
          }
          if (b.data.error.showMessage.redirectUrl) {
            router.push(b.data.error.showMessage.redirectUrl);
          }
          setTimeout(() => {
            if (!`${b.data.error.code}`.includes('-SE') && !`${b.data.error.code}`.includes('-UR')) {
              const sm = b.data.error.showMessage;
              toast.add({ group: 'headless', severity: 'warn', summary: sm.headline, detail: sm.subtitle + ' ' + sm.text, life: 30000 });
            }
            store.dispatch('setLoading', false);
          }, 1000);
          return;
        }
        await store.dispatch('setPaymentData', b);
        window.localStorage.setItem('session', b.data.config.session.value);
        window.localStorage.setItem('paymentParamsEncoded', b.data.config.paymentParamsEncoded || b.data.config.bookParamsEncoded);
        if (b.data.content.priceChange?.isChanged) {
          setTimeout(() => {
            priceChangeDialogOpened.value = true;
          }, 1000);
        }
        // if ((b.data.error && !b.data.error.redirectUrl) || b.data?.error?.redirectUrl.includes('payment')) {
        // console.log('initializing secure fields because of no redirect url, full error:' + b.data.error);
        // console.log('condition 1:', !b.data?.error?.redirectUrl);
        // console.log('condition 2:', b.data.error.redirectUrl.includes('payment'));
        setTimeout(() => {
          try {
            console.log('secureFields', b);
            secureFields.init(store.getters.paymentData.data.config.datatrans.transactionId, {
              cardNumber: 'cardNumberPlaceholder',
              cvv: 'cvvPlaceholder'
            }, {
              styles: styles
            });
            // secureFields.setStyle("cardNumber", "border: 1px solid #ccc");
            // secureFields.setStyle("cvv", "border: 1px solid #ccc");
            secureFields.on('change', function (event) {
              isDatatransCardValid.value = event?.fields?.cardNumber?.valid;
              isDatatransCvvValid.value = event?.fields?.cvv?.valid;
              datatransCardLength.value = event?.fields?.cardNumber?.length;
              datatransCvvLength.value = event?.fields?.cvv?.length;
            });
          } catch (error) {
            // do nothing
          }
        }, 2000);
        // }

        try {
          paymentMethodActiveIndex.value = b.data.content.form.payment.types.findIndex(item => {
            // console.log('comparing ', item, ' and ', b.data.content.form.presetData.formOfPayment.type);
            return item === b.data.content.form.presetData.formOfPayment.type;
          });
          paymentTypeRef.value = paymentStoreData.value.data.content.form.payment.types[paymentMethodActiveIndex.value];
        } catch (error) {
          paymentMethodActiveIndex.value = 0;
          paymentTypeRef.value = paymentStoreData.value.data.content.form.payment.types[0];
        }

        setTimeout(() => {
          sessionResolved.value = true;
          store.dispatch('setLoading', false);
          store.dispatch('setLoader', b.data.loader);
        }, 1000);
      } catch (err) {
        console.error(err);
        await store.dispatch('setPaymentData', { err: 'err' });
        router.push(`/${language.value}`);
        store.dispatch('setLoading', false);
      }
    }

    function handleSidebarPosition(e) {
      try {
        const mainContainer = document.getElementById('main-container');
        const mainContainerY = Math.max(mainContainer.offsetTop, mainContainer.scrollTop, mainContainer.clientTop);
        const mainContainerHeight = Math.max(mainContainer.clientHeight, mainContainer.offsetHeight, mainContainer.scrollHeight);
        const mainContainerBottomY = mainContainerY + mainContainerHeight;

        const sideCard = document.getElementById('side-card');
        const sideCardHeight = Math.max(sideCard.clientHeight, sideCard.offsetHeight, sideCard.scrollHeight);
        const sideCardBottomY = sideCardHeight;

        isSidebarFixed.value = window.scrollY > 265; // && fromBottom > 200;

        isSidebarDockedToBottom.value = sideCardBottomY + window.scrollY + 62 > mainContainerBottomY && window.visualViewport.width > 1200;

        isSidebarFixed.value = window.scrollY > 265 && !isSidebarDockedToBottom.value && window.visualViewport.width > 1200;
      } catch (error) {
        isSidebarFixed.value = false;
        isSidebarDockedToBottom.value = false;
      }
    }

    function checkCvvLength() {
      const isAmex = cc.value.number && cc.value.number.charAt(0) === '3';
      if (isAmex) {
        return cc.value.cvv.length === 4;
      }
      return cc.value.cvv.length === 3;
    }

    function prepareRequestObject() {
      const requestObject = {
        session: window.localStorage.getItem('session'),
        formOfPayment: {
          type: paymentTypeRef.value
        },
        agencyInfo: {
          consentPassport: consentPassport.value ? 1 : 0,
          consentVisa: consentVisa.value ? 1 : 0,
          consentTc: consentTc.value ? 1 : 0
        }
      };

      if (requestObject.formOfPayment.type?.toLowerCase() === 'cc') {
        requestObject.formOfPayment.cardInfo = {
          vendor: cc.value.number.startsWith('4') ? 'VI' : cc.value.number.startsWith('5') ? 'MC' : 'AX',
          cardHolder: cc.value.cardHolder,
          number: cc.value.number.replaceAll(' ', ''),
          expireDate: cc.value.expireDate.replaceAll('/', ''),
          cvv: cc.value.cvv
        };
      }

      return requestObject;
    }

    async function getPnrData(createPnrParamsEncoded) {
      const a = await fetch(`https://${store.getters.mainData.config.baseUrl}/${store.getters.language}/flight/create-pnr/${createPnrParamsEncoded}`);
      const b = await a.json();
      if (b.data && 'error' in b.data) {
        console.error('error', b.data.error);
        if (b.data.error.redirectUrl) {
          router.push(b.data.error.redirectUrl);
        }
        setTimeout(() => {
          toast.add({ group: 'headless', severity: 'warn', summary: '', detail: b.data.error.showMessage, life: 30000 });
          store.dispatch('setLoading', false);
        }, 1500);
        return;
      }
      store.dispatch('setPnrData', b.data);
      router.push(`/${language.value}/flight/create-pnr`);
      setTimeout(() => {
        store.dispatch('setLoading', false);
      }, 3000);
    }

    async function submitPaymentRoutine(cc = false) {
      if (validatePayment() || cc) {
        clearInterval(sessionCheckIntervalId);
        toast.removeGroup('expiry');

        // Prepare post request
        const requestObject = prepareRequestObject();

        store.dispatch('setLoading', true);

        const url = `https://${store.getters.mainData.config.baseUrl}/${store.getters.language}/flight/create-pnr`;

        if (cc) {
          requestObject.formOfPayment = {
            type: 'cc'
          };
          requestObject.agencyInfo = {
            consentPassport: 1,
            consentVisa: 1,
            consentTc: 1
          };
        }

        try {
          const response = await axios.post(url, requestObject);

          if (response.data.status !== 'success' && response.data.status !== 'failure' && response.data?.data?.error?.code !== 'DT-AUTHE') {
            console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
            console.log(response);
            console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');

            return false;
          }

          const resData = response.data.data;

          if (Object.keys(resData).includes('error')) {
            console.error(resData.error);

            if (Object.keys(resData).includes('transactionId')) {
              reinitSecureFields(resData.transactionId);
            }

            if (resData.error.redirectUrl) {
              router.push(resData.error.redirectUrl);
              if (resData.error.redirectUrl.length < 5) {
                setTimeout(() => {
                  store.dispatch('setLoading', false);
                }, 5000);
              }
            }
            setTimeout(() => {
              if (!`${resData.error.code}`.includes('-SE') && !`${resData.error.code}`.includes('-UR')) {
                toast.add({ group: 'headless', severity: 'warn', summary: '', detail: resData.error.showMessage, life: 30000 });
              }
            }, 400);
            sessionCheckIntervalId = setInterval(() => {
              setFinalInterval();
            }, 60000);
            return false;
          }

          const config = resData.config;
          store.dispatch('setSession', config.session);
          store.dispatch('setCreatePnrParamsEncoded', config.createPnrParamsEncoded);
          window.localStorage.setItem('createPnrParamsEncoded', config.createPnrParamsEncoded);

          if ('loader' in response || (resData.content && 'loader' in resData.content)) {
            setTimeout(() => {
              const rdd = response.data.data;
              store.dispatch('setLoader', rdd.loader || (rdd.content && 'loader' in rdd.content && rdd.content.loader));
            }, 5000);
          }

          getPnrData(resData.config.createPnrParamsEncoded);
        } catch (error) {
          console.error('Request failed:', error);
        } finally {
          setTimeout(() => {
            store.dispatch('setLoading', false);
          }, 2000);
        }
        return true;
      }
    }
  }
};
